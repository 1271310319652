function TopBar(props: {
    headerText: string
    addressText: string
}) {
    return <div className="l-topBar topBar">
        <div className="l-container">
            <div className="inner">
                <div className="topBar_brand">
                    <span className="topBar_brand_short">{props.headerText}</span>
                </div>
                <address className="topBar_addr">{props.addressText}</address>
            </div>
        </div>
    </div>;
}

export default TopBar;
