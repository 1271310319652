import {HomePageContent} from "../../../data/PageContent";
import {Link} from "react-router-dom";
import {HomeMotorcycleView} from "./HomeMotorcycleView";
import {HomeSlider} from "./HomeSlider";
import {Product} from "../../../data/Product";

function HomePageView(props: {
    content: HomePageContent
}) {
    const motorcycle = getMotorcycleForToday(props.content.motorcycles)
    const sliderItems = props.content.sliderItems
    return <div>
        <div className="l-mainTeaser mainTeaser">
            <div className="mainTeaser_content">
                <div className="mainTeaser_content_link">
                    <a className="button button-white button-teaser-video" data-fancybox=""
                       href={props.content.teaserVideoUrl}>
                    <span className="label text-white">
                        Play
                    </span>
                    </a>
                </div>
                <div className="mainTeaser_content_text">
                    <h1>{props.content.teaserTitle}</h1>
                    <div className="button_container button_container-teaser">
                        <a className="button button-white button-teaser button-video" data-fancybox=""
                           href={props.content.teaserVideoUrl}>
                            Смотреть видео
                        </a>

                        {/* TODO: should be a link on some news article */}
                        <Link className="button button-orange button-teaser" to="/">Читать подробнее</Link>
                    </div>
                </div>
            </div>
            <div className="mainTeaser_bg">
                <img src={props.content.teaserImage.originalUrl} alt=""/>
            </div>
        </div>
        {sliderItems.length > 0 && <HomeSlider items={sliderItems}/>}
        {motorcycle && <HomeMotorcycleView motorcycle={motorcycle}/>}
        <div className="offer offer-parts">
            <h2>{props.content.introTitle}</h2>
            <p>{props.content.introBody}</p>
            <Link to="/about" className="button--line">Подробнее</Link>
        </div>
    </div>;
}

function getMotorcycleForToday(motorcycles: Product[]): Product | null {
    if (motorcycles.length === 0) return null
    let dayOfMonth = new Date().getDate()
    return motorcycles[dayOfMonth % motorcycles.length]
}

export default HomePageView;
