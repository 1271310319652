import {ImageWithTextContent} from "../../data/PageContent";
import parse from "html-react-parser";

export function ImageWithTextView(props: {
    content: ImageWithTextContent,
}) {
    const imageAlignment = props.content.imageAlignment
    return <div className="article_media">
        <div
            className={`l-grid-half ${imageAlignment === 'Right' ? 'l-grid-reverse' : ''} ${imageAlignment === 'Center' ? 'l-grid-center' : ''}`}>
            <figure>
                <img src={props.content.image.originalUrl} className="lazyload" alt=""/>
            </figure>
            <div className="text">
                {parse(props.content.textHtml)}
            </div>
        </div>
    </div>
}
