import {configureStore} from '@reduxjs/toolkit'
import {cartSlice} from "./CartSlice";
import {loadAppStateFromLocalStorage} from "./LocalStorage";
import {userSlice} from "./UserSlice";

const appStore = configureStore({
    reducer: {
        cart: cartSlice.reducer,
        user: userSlice.reducer,
    },
    preloadedState: loadAppStateFromLocalStorage(),
})

export type AppState = ReturnType<typeof appStore.getState>
export type AppDispatch = typeof appStore.dispatch

export function getAppState(): AppState {
    return appStore.getState()
}

export default appStore
