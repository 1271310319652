import {fetchJson} from "./FetchJson";

export type MotoType = string

export const getMotoTypes: Promise<MotoType[]> = fetchJson('/api/mototypes')
    .then((json) => (json.data as any[]).map(parseMotoType))

function parseMotoType(json: any): MotoType {
    return json.title
}
