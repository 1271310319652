import {fetchJson} from "./FetchJson";
import {Image, parseImage} from "./Images";
import {Currency, parseCurrency} from "./Currency";

export interface CatalogSettings {
    imagePlaceholder: Image
    primaryCurrency: Currency
    secondaryCurrencies: Currency[]
}

export const getCatalogSettings: Promise<CatalogSettings> = fetchJson('/api/catalog-settings?populate=deep')
    .then((json) => {
        let data = json.data
        return {
            imagePlaceholder: parseImage(data.imagePlaceholder),
            primaryCurrency: parseCurrency(data.primaryCurrency),
            secondaryCurrencies: data.secondaryCurrencies.map(parseCurrency),
        }
    })
