export function OutlinedButton(props: {
    label: string,
    enabled?: boolean,
    onClick: () => void,
}) {
    const isEnabled = !(props.enabled === false)
    return <div
        className="button button-clean-orange"
        style={{
            width: "fit-content",
            opacity: (isEnabled && 1) || 0.5,
            pointerEvents: (isEnabled && "auto") || "none",
        }}
        onClick={props.onClick}
    >{props.label}</div>
}
