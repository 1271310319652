import './App.css';
import {HelmetProvider} from "react-helmet-async";
import React from "react";
import {getGlobalSettings} from "../data/GlobalSettings";
import {AppContent} from "./AppContent";
import Loader from "./primitives/Loader";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import {Fancybox} from "@fancyapps/ui";
import {useData} from "../functional/Hooks";
import {MetaData} from "./MetaData";

const App = () => {
    const globalSettings = useData(getGlobalSettings)
    Fancybox.bind('[data-fancybox]')
    return <HelmetProvider>
        <MetaData/>
        <div className="App">
            {globalSettings && <AppContent globalSettings={globalSettings}/>}
            {!globalSettings && <Loader/>}
        </div>
    </HelmetProvider>
}

export default App;
