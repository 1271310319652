import "./LoginView.css"
import {OutlinedButton} from "../primitives/OutlinedButton";
import {loginUser} from "../../data/User";
import {setToken} from "../../redux/UserSlice";
import {useDispatch} from "react-redux";
import {PageHeadingPlaceholder} from "../PageHeadingPlaceholder";
import {useEmailInputField, useNonEmptyInputField} from "../inputs/InputFields";
import React, {useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import Loader from "../primitives/Loader";
import Alert from "@mui/material/Alert";
import {PasswordInput} from "../inputs/Inputs";

export function LoginView() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [isLoggingIn, setIsLoggingIn] = useState(false)
    const [errorMessage, setErrorMessage] = useState<string | null>(null)
    const [renderEmailInput, validateEmail] = useEmailInputField()
    const [renderPasswordInput, validatePassword] = useNonEmptyInputField("Пароль", "", PasswordInput)
    const onLoginClick = () => {
        setErrorMessage(null)
        let [email, password] = [
            validateEmail(),
            validatePassword(),
        ]
        if (email !== null && password !== null) {
            setIsLoggingIn(true)
            loginUser({
                email: email,
                password: password,
            }).then(token => {
                dispatch(setToken(token))
                setIsLoggingIn(false)
                navigate('/')
            }).catch(() => {
                setIsLoggingIn(false)
                setErrorMessage("Неверный email или пароль")
            })
        }
    }
    return <>
        <PageHeadingPlaceholder/>
        <div className="Login Flex Center">
            <div className="LoginForm">
                <br/>
                <h1>Вход в учетную запись</h1>
                <br/>
                <br/>
                <div className="l-feedback feedback">
                    <div className="feedback_inputs">
                        {renderEmailInput()}
                        {renderPasswordInput()}
                    </div>
                    <Link to={`/register`}><small>Регистрация</small></Link>
                </div>
                <br/>
                <br/>
                {isLoggingIn && <Loader/>}
                {!isLoggingIn && <OutlinedButton label="Войти" onClick={onLoginClick}/>}
                <br/>
                <br/>
                {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
            </div>
        </div>
    </>
}
