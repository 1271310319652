import {ProductSpecs, ProductSpecsGroup} from "../../../data/Product";
import React, {ReactNode, useState} from "react";
import Immutable from "immutable";

export function ProductSpecsView(props: {
    productSpecs: ProductSpecs,
}) {
    const [specsExpanded, setSpecsExpanded] = useState(false)
    const getGroupClass = (index: number) => {
        let firstClass = index === 0 ? `first` : ``
        let secondClass = !specsExpanded ? index === 0 ? `is-closed` : `is-hidden` : ``
        return `${firstClass} ${secondClass}`
    }
    return <>
        {props.productSpecs.size > 0 && <div className="l-container is-nopadded">
            <section className="product-overview-details">
                <h3 className="product-overview-details_header">Характеристики</h3>
                {mapToReactNodes(props.productSpecs, ([group, specs], index) =>
                    <dl className={`product-specs ${getGroupClass(index)}`}>
                        <SpecsGroup
                            groupTitle={group}
                            groupSpecs={specs}
                            numberOfVisibleItems={specsExpanded ? specs.size : index === 0 ? 5 : 0}
                        />
                    </dl>)}
                {!specsExpanded && <ShowAllButton onClick={() => setSpecsExpanded(true)}/>}
            </section>
        </div>}
    </>
}

function ShowAllButton(props: {
    onClick: () => void,
}) {
    return <button className="product-specs-showAll h9" onClick={props.onClick}>Показать все характеристики</button>
}

function SpecsGroup(props: {
    groupTitle: string,
    groupSpecs: ProductSpecsGroup,
    numberOfVisibleItems: number,
}) {
    const getItemClass = (index: number) => index < props.numberOfVisibleItems ? `` : `is-hidden`
    return <>
        <dt className="product-specs_term h3">{props.groupTitle}</dt>
        <dd className="product-specs_def">
            <dl className="product-specs-list">
                {mapToReactNodes(props.groupSpecs, ([specTitle, specValue], index) => <>
                    <dt className={`product-specs-list_term h5 ${getItemClass(index)}`}>{specTitle}</dt>
                    <dd className={`product-specs-list_def p2 ${getItemClass(index)}`}>{specValue}</dd>
                </>)}
            </dl>
        </dd>
    </>
}

function mapToReactNodes<K, V, Node extends ReactNode>(
    data: Immutable.Map<K, V>,
    mapper: (entry: [K, V], index: number) => Node,
): ReactNode[] {
    return React.Children.toArray(data.toArray().map(mapper))
}
