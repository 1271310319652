import {getUserProfile, UserProfile} from "../data/UserProfile";
import {useAppSelector} from "../redux/Hooks";
import {useData} from "../functional/Hooks";
import {useCallback} from "react";
import {UserToken} from "../data/User";

export function useUserToken(): UserToken | null {
    return useAppSelector(state => state.user.token)
}

export function useUserProfile(): undefined | UserProfile | null {
    const token = useUserToken()
    return useData(useCallback(() => getUserProfile(token), [token]))
}
