import {fetchJson} from "./FetchJson";

export interface Page {
    id: string
    slug: string
}

export function getPages(): Promise<Page[]> {
    return fetchJson('/api/pages')
        .then((json) => (json.data as any[]).map(parsePage))
}

function parsePage(jsonPage: any): Page {
    return {
        id: jsonPage.id,
        slug: jsonPage.slug || "",
    }
}
