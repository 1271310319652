import {ContactsContent} from "../../../data/PageContent";
import parse from 'html-react-parser';
import {Map} from "./Map";
import {FeedbackView} from "../feedback/FeedbackView";

function ContactsView(props: {
    content: ContactsContent
}) {
    return <div className="l-contact contact">
        <div className="contact_info">
            <div className="contact_info_txt">
                <h1>Контакты</h1>
                <p>{parse(props.content.text.replaceAll('\n', "<br>"))}</p>
                <h3>Телефоны</h3>
                <ul>
                    {props.content.phones.map((phoneString, index) => <li key={index}>
                        {phoneString}
                    </li>)}
                </ul>
                <h3>Viber</h3>
                <ul>
                    {props.content.viber.map((viberString, index) => <li key={index}>
                        {viberString}
                    </li>)}
                </ul>
                <h3>Почта</h3>
                <ul>
                    {props.content.emails.map((emailString, index) => <li key={index}>
                        {emailString}
                    </li>)}
                </ul>
            </div>
            <Map
                coordinates={props.content.coordinates}
                markerIconUrl={props.content.mapMarkerIconUrl}
            />
        </div>
        <FeedbackView/>
        <div style={{margin: '20px'}}>
            {parse(props.content.extraTextHtml)}
        </div>

    </div>;
}

export default ContactsView;
