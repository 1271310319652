import {Coordinates} from "./Coordinates";
import {SliderItemData} from "./SliderItemData";
import {Image} from "./Images";
import {Product} from "./Product";

export class PageContent {
    assumesTransparentNavBar: boolean = false
}

export class HomePageContent extends PageContent {
    override assumesTransparentNavBar = true

    teaserTitle: string
    teaserVideoUrl: string
    teaserImage: Image
    introTitle: string
    introBody: string
    motorcycles: Product[]
    sliderItems: SliderItemData[]

    constructor(
        teaserTitle: string,
        teaserVideoUrl: string,
        teaserImage: Image,
        introTitle: string,
        introBody: string,
        motorcycles: Product[],
        sliderItems: SliderItemData[],
    ) {
        super()
        this.teaserTitle = teaserTitle
        this.teaserVideoUrl = teaserVideoUrl
        this.teaserImage = teaserImage
        this.introTitle = introTitle
        this.introBody = introBody
        this.motorcycles = motorcycles
        this.sliderItems = sliderItems
    }
}

export class HtmlContent extends PageContent {
    title: string
    body: string

    constructor(
        title: string,
        body: string,
    ) {
        super()
        this.title = title
        this.body = body
    }
}

export class SeparatedHtmlContent extends PageContent {
    asideTitle: string
    asideDescription: string
    body: HtmlContent[]

    constructor(
        asideTitle: string,
        asideDescription: string,
        body: HtmlContent[],
    ) {
        super()
        this.asideTitle = asideTitle
        this.asideDescription = asideDescription
        this.body = body
    }
}

export class ServicePreviewContent extends PageContent {
    asideTitle: string
    asideDescription: string
    targetPageSlug: string
    image: Image

    constructor(
        asideTitle: string,
        asideDescription: string,
        targetPageSlug: string,
        image: Image,
    ) {
        super()
        this.asideTitle = asideTitle
        this.asideDescription = asideDescription
        this.targetPageSlug = targetPageSlug
        this.image = image
    }
}

export class ServiceTableContent extends PageContent {

    items: ServiceTableContent.Item[];

    constructor(
        items: ServiceTableContent.Item[],
    ) {
        super()
        this.items = items
    }
}

export namespace ServiceTableContent {
    export class Item {
        title: string
        description: string

        constructor(
            title: string,
            description: string,
        ) {
            this.title = title
            this.description = description
        }
    }
}

export class CatalogContent extends PageContent {

}

export class CartContent extends PageContent {

}

export class OrderContent extends PageContent {

}

export class ContactsContent extends PageContent {
    text: string
    coordinates: Coordinates
    phones: string[]
    viber: string[]
    emails: string[]
    extraTextHtml: string
    mapMarkerIconUrl: string

    constructor(
        text: string,
        coordinates: Coordinates,
        phones: string[],
        viber: string[],
        emails: string[],
        extraTextHtml: string,
        mapMarkerIconUrl: string,
    ) {
        super()
        this.text = text
        this.coordinates = coordinates
        this.phones = phones
        this.viber = viber
        this.emails = emails
        this.extraTextHtml = extraTextHtml
        this.mapMarkerIconUrl = mapMarkerIconUrl
    }
}

export class NewsContent extends PageContent {

}

export class ImagesGridContent extends PageContent {
    images: Image[]

    constructor(
        images: Image[],
    ) {
        super()
        this.images = images
    }
}

export class ImageWithTextContent extends PageContent {
    textHtml: string
    image: Image
    imageAlignment: string

    constructor(
        textHtml: string,
        image: Image,
        imageAlignment: string,
    ) {
        super()
        this.textHtml = textHtml
        this.image = image
        this.imageAlignment = imageAlignment
    }
}

export class ButtonContent extends PageContent {
    title: string
    url: string

    constructor(
        title: string,
        url: string,
    ) {
        super()
        this.title = title
        this.url = url
    }
}

export class CoverContent extends PageContent {
    image: Image

    constructor(
        image: Image,
    ) {
        super()
        this.image = image
    }
}
