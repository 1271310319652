import "./InputField.css"
import React, {FunctionComponent, useState} from "react";

export interface InputProps {
    value: string,
    placeholderText: string,
    onValueChanged: (newValue: string) => void,
}

export type InputFieldController = [
    render: () => React.ReactComponentElement<any>,
    triggerValidation: () => string | null,
    clear: () => void,
]

export function useInputField(
    input: FunctionComponent<InputProps>,
    placeholderText: string,
    initialValue: string,
    validate: (inputValue: string) => string | null,
): InputFieldController {
    const [errorMessage, setErrorMessage] = useState<string | null>(null)
    const [value, setValue] = useState<string | null>(null)
    const actualValue = value || initialValue
    const onValueChanged = (newValue: string) => {
        setErrorMessage(null)
        setValue(newValue)
    }
    const triggerValidation = () => {
        let validationError = validate(actualValue)
        setErrorMessage(validationError)
        if (validationError) {
            return null
        } else {
            return actualValue
        }
    }
    const render = () => <InputField
        value={actualValue}
        placeholderText={placeholderText}
        onValueChanged={onValueChanged}
        input={input}
        errorMessage={errorMessage}
    />
    const clear = () => onValueChanged("")
    return [render, triggerValidation, clear]
}

interface InputFieldProps extends InputProps {
    input: FunctionComponent<InputProps>,
    errorMessage: string | null
}

function InputField(props: InputFieldProps) {
    return <div className={`InputField ${(props.errorMessage && `InputFieldError`) || ``}`}>
        {props.errorMessage && <small>{props.errorMessage}</small>}
        {React.createElement(props.input, props)}
    </div>
}
