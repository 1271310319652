import "./NoItemsLayout.css"
import {CatalogCategory} from "../../../data/CatalogCategory";
import React from "react";
import {FeedbackView} from "../feedback/FeedbackView";

export function NoItemsLayout(props: {
    category: CatalogCategory,
}) {
    switch (props.category) {
        case CatalogCategory.MOTORCYCLES:
            return <>
                Нет товаров по указанным критериям.
            </>
        case CatalogCategory.PARTS:
        case CatalogCategory.ACCESSORIES:
            return <div className="l-container l-container-column">
                <div className="NoItemsLayoutMessage">
                    Если вы не нашли нужный вам товар, можете сделать запрос на нужный продукт через обратную связь
                </div>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <FeedbackView/>
            </div>
    }
}
