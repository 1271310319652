import {Image, parseImage} from "./Images";
import {Color, parseColor} from "./Color";

export interface CatalogItemVariant {
    id: number
    sku: string
    qualifier: string | null
    color: Color | null
    price: number
    oldPrice: number | null
    inStock: boolean
    images: Image[]
}

export function parseCatalogItemVariant(json: any): CatalogItemVariant {
    return {
        id: json.id,
        sku: json.sku,
        qualifier: json.qualifier,
        color: json.color && parseColor(json.color),
        price: json.discountedPrice || json.price,
        oldPrice: json.discountedPrice && json.price,
        inStock: json.stock > 0,
        images: (json.images as any[])?.map(parseImage) || [],
    }
}
