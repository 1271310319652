import {fetchJson} from "./FetchJson";

export type AccessoryType = {
    title: string
    filter: string | null
}

export const getAccessoryTypes: Promise<AccessoryType[]> = fetchJson('/api/accessory-types')
    .then((json) => (json.data as any[]).map(parseAccessoryType))

function parseAccessoryType(json: any): AccessoryType {
    return {
        title: json.title,
        filter: json.filter,
    }
}
