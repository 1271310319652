import {fetchJson} from "./FetchJson";
import {required} from "../functional/Functions";
import appStore from "../redux/AppStore";
import {removeToken} from "../redux/UserSlice";
import {Order, parseOrder} from "./Order";

export interface UserProfile {
    name: string
    email: string
    phone: string
    discount: number
    orders: Order[]
}

export function getUserProfile(token: string | null = appStore.getState().user.token): Promise<UserProfile | null> {
    return new Promise(resolve => {
        if (token) {
            resolve(fetchUserProfile(token).catch((error) => {
                console.log(error)
                appStore.dispatch(removeToken())
                return null
            }))
        } else {
            resolve(null)
        }
    })
}

function fetchUserProfile(token: string): Promise<UserProfile> {
    return fetchJson(`/api/users/me`).then(parseUserProfile)
}

function parseUserProfile(json: any): UserProfile {
    return {
        name: required(json.name),
        email: required(json.email),
        phone: required(json.phone),
        discount: required(json.discount),
        orders: json.orders.map(parseOrder),
    }
}
