import {HtmlContent, SeparatedHtmlContent} from "../../data/PageContent";
import parse from "html-react-parser";

function SeparatedHtmlView(props: {
    content: SeparatedHtmlContent
}) {
    return <div className="l-container">
        <div className="separated">
            <div className="separated_intro">
                <h2 className="separated_header">{props.content.asideTitle}</h2>
                <p>{parse(props.content.asideDescription)}</p>
            </div>
            <div className="separated_content text">
                {
                    props.content.body.map((htmlContent, index, content) => <div key={index}>
                        <HtmlWithTitle content={htmlContent}/>
                        {index < content.length - 1 && <hr/>}
                    </div>)
                }
            </div>
        </div>
    </div>;
}

function HtmlWithTitle(props: {
    content: HtmlContent
}) {
    return <div>
        <h2 className="separated_header">{props.content.title}</h2>
        {parse(props.content.body)}
    </div>;
}

export default SeparatedHtmlView;
