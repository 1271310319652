import "./FeedbackView.css"
import {useEmailInputField, useMobileInputField, useNonEmptyInputField} from "../../inputs/InputFields";
import {sendFeedback} from "../../../data/Feedback";
import {nonEmptyOrNull} from "../../../functional/Strings";
import Alert from "@mui/material/Alert";
import {useState} from "react";
import Loader from "../../primitives/Loader";
import {TextAreaInput} from "../../inputs/Inputs";

enum FeedbackState {
    Idle,
    Sending,
    Sent,
    FailedToSend,
}

export const FeedbackView = () => {
    const [renderNameInput, validateName, clearName] = useNonEmptyInputField("ФИО")
    const [renderEmailInput, validateEmail, clearEmail] = useEmailInputField()
    const [renderPhoneInput, validatePhone, clearPhone] = useMobileInputField()
    const [renderMessageInput, validateMessage, clearMessage] = useNonEmptyInputField("Текст сообщения", "", TextAreaInput)
    const [feedbackState, setFeedbackState] = useState(FeedbackState.Idle)
    const onSendFeedback = () => {
        let inputValues = [validateName(), validateEmail(), validatePhone(), validateMessage()]
        if (!inputValues.some(value => value === null) && feedbackState !== FeedbackState.Sending) {
            let [name, email, phone, message] = inputValues
            setFeedbackState(FeedbackState.Sending)
            sendFeedback({
                contacts: {
                    name: name!!,
                    email: nonEmptyOrNull(email!!),
                    phone: phone!!,
                },
                message: message!!,
            }).then(() => {
                clearName()
                clearEmail()
                clearPhone()
                clearMessage()
                setFeedbackState(FeedbackState.Sent)
            }).catch(() => setFeedbackState(FeedbackState.FailedToSend))
        }
    }
    return <>
        <div className="FeedbackForm">
            <div className="l-feedback feedback">
                <h2 className="feedback_title">Обратная связь</h2>
                <div className="feedback_area">
                    <div className="feedback_inputs">
                        {renderNameInput()}
                        {renderEmailInput()}
                        {renderPhoneInput()}
                    </div>
                    <div className="feedback_text">
                        {renderMessageInput()}
                    </div>
                </div>
                <button className="button" onClick={onSendFeedback}>Отправить сообщение</button>
            </div>
        </div>
        <div className="FeedbackAlert">
            {feedbackState === FeedbackState.Sending && <Loader/>}
            {feedbackState === FeedbackState.Sent &&
                <Alert severity="success">Ваше обращение успешно отправлено</Alert>}
            {feedbackState === FeedbackState.FailedToSend &&
                <Alert severity="error">Не удалось отправить обращение. Попробуйте позже</Alert>}
        </div>
    </>
}
