import {ServiceTableContent} from "../../data/PageContent";

function ServiceTableView(props: {
    content: ServiceTableContent
}) {
    return <div className="l-container">
        <div className="l-productTable productTable">
            <ul className="detailList">
                {props.content.items.map((item, index) => <li className="detailList_item" key={index}>
                    <h6>{item.title}</h6>
                    <p>{item.description}</p>
                </li>)}
            </ul>
        </div>
    </div>;
}

export default ServiceTableView;
