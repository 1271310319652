import {useData} from "../functional/Hooks";
import {getGlobalMetaData} from "../data/MetaData";
import {Helmet} from "react-helmet-async";
import React from "react";
import {isDefined, notNull} from "../functional/Functions";

export function MetaData(props: {
    title?: string,
    description?: string,
    extraKeywords?: string[],
}) {
    const globalMeta = useData(() => getGlobalMetaData)
    const title = assembleMetaTag([props.title, globalMeta?.title])
    const description = sanitizeMetaTag(props.description || globalMeta?.description || "")
    const keywords = [globalMeta?.keywords || [], ...(props.extraKeywords || [])].join(", ")
    return <>
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={description}/>
            <meta name="keywords" content={keywords}/>
            {globalMeta && <link rel="icon" href={globalMeta.faviconUrl}/>}
        </Helmet>
    </>
}

function assembleMetaTag(parts: (string | undefined | null)[]): string {
    return parts.filter(isDefined).filter(notNull).join(" | ")
}

function sanitizeMetaTag(tag: string): string {
    return tag.replaceAll("\n", " ")
}
