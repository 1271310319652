export interface Currency {
    code: string,
    rate: number,
}

export function parseCurrency(json: any): Currency {
    return {
        code: json.code,
        rate: json.rate,
    }
}
