import {postJson} from "./FetchJson";
import {CartEntry} from "./CartEntry";
import {CatalogCategory} from "./CatalogCategory";
import {Contacts} from "./Contacts";
import {CatalogItem} from "./CatalogItems";
import {ShippingMethod, ShippingMethodId} from "./ShippingMethod";
import {PaymentMethod} from "./PaymentMethod";
import {CatalogSettings} from "./CatalogSettings";
import {roundedPrice} from "./Price";
import {DeliveryOffice} from "./DeliveryOffice";

export function createOrder(
    items: (CartEntry & CatalogItem)[],
    catalogSettings: CatalogSettings,
    shippingMethod: ShippingMethod | null,
    deliveryOffice: DeliveryOffice | null,
    paymentMethod: PaymentMethod | null,
    contacts: Contacts,
    comments: string,
): Promise<any> {
    return postJson("/api/orders", {
        data: {
            items: items.map((item) => {
                let selectedVariant = item.variants.find((variant) => variant.id === item.variantId)
                if (!selectedVariant) {
                    throw Error
                }
                return {
                    title: item.title,
                    motorcycle: ((item.category === CatalogCategory.MOTORCYCLES) && item.id) || null,
                    part: ((item.category === CatalogCategory.PARTS) && item.id) || null,
                    accessory: ((item.category === CatalogCategory.ACCESSORIES) && item.id) || null,
                    quantity: item.count,
                    sku: selectedVariant.sku,
                    price: roundedPrice(item.category, selectedVariant.price, catalogSettings.primaryCurrency),
                    qualifier: selectedVariant.qualifier,
                    color: selectedVariant.color?.id,
                }
            }),
            currency: catalogSettings.primaryCurrency.code,
            shippingMethod: shippingMethod?.title || "",
            paymentMethod: paymentMethod?.title || "",
            evropo4taOffice: (shippingMethod?.id === ShippingMethodId.Evropo4ta && deliveryOffice?.id) || null,
            contacts: {
                name: contacts.name,
                email: contacts.email,
                phone: contacts.phone,
            },
            comments: comments,
        }
    }).then(response => {
        if (!response.data) throw Error
    });
}
