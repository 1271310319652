import './CheckBox.css';

function CheckBox(props: {
    id: string
    label: string
    checked: boolean
    onChange: () => void
}) {
    return <div className="CheckBox" key={props.id}>
        <input
            type="checkbox"
            id={`custom-checkbox-${props.id}`}
            checked={props.checked}
            onChange={props.onChange}
        />
        <label htmlFor={`custom-checkbox-${props.id}`}>{props.label}</label>
    </div>;
}

export default CheckBox;
