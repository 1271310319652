import "./CurrencySelector.css"
import React from "react";
import {Currency} from "../../../data/Currency";

export function CurrencySelector(props: {
    currencies: Currency[],
    selectedCurrency: Currency,
    setCurrency: (index: number) => void,
}) {
    const PriceItem = (currency: Currency, index: number) => {
        let itemStyle = "CurrencySelectorItem " + (currency.code === props.selectedCurrency.code && "Active") || ""
        return <li key={index} className={itemStyle}
                   onClick={() => props.setCurrency(index)}
                   title={currency.code}>
            <div className="CurrencySelectorItemTitle">
                {currency.code}
            </div>
        </li>
    }
    return <>
        {props.currencies.length > 0 && <ul className="CurrencySelector">
            {props.currencies.map(PriceItem)}
        </ul>}
    </>
}
