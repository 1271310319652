import {CatalogCategory} from "../../../data/CatalogCategory";
import {getProduct} from "../../../data/Product";
import {getCatalogSettings} from "../../../data/CatalogSettings";
import {ProductView} from "./ProductView";
import React from "react";
import {DataEntryView} from "../DataEntryView";

export function ProductEntryView(props: {
    category: CatalogCategory,
}) {
    const getDataWithCatalogSettings = (id: number) => Promise.all([getProduct(id, props.category), getCatalogSettings])
        .then(([maybeProduct, catalogSettings]) => {
            return maybeProduct.map((product) => {
                return {
                    ...product,
                    ...catalogSettings,
                }
            })
        })
    return <DataEntryView
        getData={getDataWithCatalogSettings}
        dataView={ProductView}
    />
}
