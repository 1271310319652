import {fetchJson} from "./FetchJson";
import {PageContent} from "./PageContent";
import {PageHeading, parsePageHeading} from "./PageHeading";
import {parsePageContent} from "./PageContentParsing";

export interface PageDetails {
    title: string
    heading: PageHeading | null
    content: PageContent[]
}

export function getPageDetails(pageId: string): Promise<PageDetails> {
    return fetchJson('/api/pages/' + pageId + '?populate=deep,10')
        .then((json) => {
            let data = json.data
            return {
                title: data.title,
                heading: data.heading && parsePageHeading(data.heading),
                content: (data.content as any[]).map(parsePageContent)
            }
        })
}
