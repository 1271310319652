export interface ShippingMethod {
    id: number
    title: string
}

export function parseShippingMethod(json: any): ShippingMethod {
    return {
        id: json.id,
        title: json.title,
    }
}

export enum ShippingMethodId {
    Evropo4ta = 1,
}
