import {InputFieldController, InputProps, useInputField} from "./InputField";
import {MobilePhoneInput, TextInput} from "./Inputs";
import isMobilePhone from "validator/lib/isMobilePhone";
import isEmail from "validator/lib/isEmail";
import {FunctionComponent} from "react";

export function useNonEmptyInputField(
    placeholderText: string,
    initialValue: string = "",
    input: FunctionComponent<InputProps> = TextInput,
): InputFieldController {
    return useInputField(
        input,
        placeholderText,
        initialValue,
        (name) => (name.length === 0 && "Поле должно быть заполнено") || null,
    )
}

export function useEmailInputField(
    initialValue: string = "",
): InputFieldController {
    return useInputField(
        TextInput,
        "Email",
        initialValue,
        (email) => (!isEmail(email) && "Некорретный email") || null,
    )
}

export function useMobileInputField(
    initialValue: string = "",
): InputFieldController {
    return useInputField(
        MobilePhoneInput,
        "Номер телефона",
        initialValue,
        (phone) => {
            if (phone.length === 0) {
                return "Введите номер телефона"
            } else if (!isMobilePhone(phone.replace(/\D/g, ''), ['be-BY', 'ru-RU'])) {
                return "Некорректный номер телефона"
            } else {
                return null
            }
        },
    )
}
