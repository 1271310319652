import {ServicePreviewContent} from "../../data/PageContent";
import {Link} from "react-router-dom";

function ServicePreviewView(props: {
    content: ServicePreviewContent
}) {
    return <div className="service">
        <div className="service_bg">
            <img src={props.content.image.originalUrl} alt=""/>
        </div>
        <div className="service_content">
            <div className="l-container">
                <div className="separated">
                    <div className="separated_intro">
                        <h2 className="separated_header">{props.content.asideTitle}</h2>
                        <p>{props.content.asideDescription}</p>
                        <Link to={`/${props.content.targetPageSlug}`}
                              className="button button-clean button--tiny">Подробнее &raquo;</Link>
                        <div className="dark-background"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>;
}

export default ServicePreviewView;
