import {fetchJson} from "./FetchJson";

export type PartType = {
    title: string
}

export const getPartTypes: Promise<PartType[]> = fetchJson('/api/part-types?pagination[pageSize]=100&sort=title:asc')
    .then((json) => (json.data as any[]).map(parsePartType))

function parsePartType(json: any): PartType {
    return {
        title: json.title,
    }
}
