import {EmptyCart} from "./EmptyCart";
import {CartItems} from "./CartItems";
import Loader from "../../primitives/Loader";
import {useData} from "../../../functional/Hooks";
import {getCatalogSettings} from "../../../data/CatalogSettings";
import {CartContent} from "../../../data/PageContent";
import {useNavigate} from "react-router-dom";
import {useCartItems} from "../../../hooks/CartItems";

export function CartView(props: {
    content: CartContent,
}) {
    const catalogSettings = useData(() => getCatalogSettings)
    const cartItems = useCartItems()
    const navigate = useNavigate()
    return <>
        {!(cartItems && catalogSettings) && <Loader/>}
        {catalogSettings && cartItems && cartItems.length === 0 && <EmptyCart/>}
        {catalogSettings && cartItems && cartItems.length > 0 &&
            <CartItems
                items={cartItems}
                catalogSettings={catalogSettings}
                onSubmit={() => navigate('/order')}
            />}
    </>
}
