import {CatalogSettings} from "../../data/CatalogSettings";
import {CatalogItemVariant} from "../../data/CatalogItemVariant";
import {Currency} from "../../data/Currency";
import {roundedPrice} from "../../data/Price";
import {CatalogCategory} from "../../data/CatalogCategory";

export function PriceView(props: {
    catalogCategory: CatalogCategory,
    catalogItemVariant: CatalogItemVariant,
    catalogSettings: CatalogSettings,
    selectedCurrency: Currency,
}) {
    const price = props.catalogItemVariant.price
    const oldPrice = props.catalogItemVariant.oldPrice
    const formattedPrice = `${roundedPrice(props.catalogCategory, price, props.selectedCurrency).toFixed(2)} ${props.selectedCurrency.code}`
    const formattedOldPrice = oldPrice && `${roundedPrice(props.catalogCategory, oldPrice, props.selectedCurrency).toFixed(2)}`
    return <>
        {formattedOldPrice && <><s>{formattedOldPrice}</s> {formattedPrice}</>}
        {!formattedOldPrice && <>{formattedPrice}</>}
    </>
}
