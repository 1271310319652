import {Link} from "react-router-dom";

function Footer(props: { footerText: string }) {
    return <footer className="l-footer footer">
        <nav className="siteNav l-container">
            <div className="row">
                <Link to="/news">Новости</Link>
                <Link to="/catalog?category=motorcycles">Мотоциклы</Link>
                <Link to="/catalog?category=parts">Запчасти</Link>
                <Link to="/catalog?category=accessories">Экипировка</Link>
            </div>
            <div className="row">
                <Link to="/service">Сервис-центр КТМ</Link>
                <Link to="/leasing">Рассрочка</Link>
                <Link to="/about">О нас</Link>
                <Link to="/contacts">Контакты</Link>
            </div>
            <a href="#top" className="top">Наверх &uarr;</a>
        </nav>
        <div className="copy l-container">
            <small>{props.footerText}</small>
        </div>

    </footer>;
}

export default Footer;
