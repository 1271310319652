import {AppState} from "./AppStore";
import {throttle} from "throttle-typescript";

const LOCAL_STORAGE_ITEM_KEY = "appState"

export function loadAppStateFromLocalStorage() {
    try {
        let json = localStorage.getItem(LOCAL_STORAGE_ITEM_KEY)
        if (json) {
            return JSON.parse(json)
        } else {
            return undefined
        }
    } catch {
        console.log("Error reading app state from local storage")
    }
}

export function saveAppStateToLocalStorage(getState: () => AppState) {
    throttle(() => {
        try {
            localStorage.setItem(LOCAL_STORAGE_ITEM_KEY, JSON.stringify(getState()))
        } catch {
            console.log("Error writing app state to local storage")
        }
    }, 100)()
}
