import {CoverContent} from "../../data/PageContent";

export function CoverView(props: {
    content: CoverContent,
}) {
    return <div className="bg_cover">
        <img src={props.content.image.originalUrl} alt=""/>
        <br/>
        <br/>
    </div>
}
