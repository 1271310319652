import React, {FunctionComponent, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import Loader from "../primitives/Loader";
import {PageHeadingPlaceholder} from "../PageHeadingPlaceholder";
import {Option} from "@thames/monads";
import {Content404} from "../Page404";

export function DataEntryView<Data extends {}>(props: {
    getData: (id: number) => Promise<Option<Data>>,
    dataView: FunctionComponent<Data>,
}) {
    const {id} = useParams()
    const [maybeData, setMaybeData] = useState<Option<Data> | null>(null)
    useEffect(() => {
        id && props.getData(parseInt(id)).then(setMaybeData)
    }, [id, props])
    const Content = () => {
        if (maybeData) {
            return maybeData.match({
                some: (data) => React.createElement(props.dataView, data),
                none: <Content404/>
            })
        } else {
            return <Loader/>
        }
    }
    return <>
        <PageHeadingPlaceholder/>
        <Content/>
    </>
}
