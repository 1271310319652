export function sum(a: number, b: number): number {
    return a + b
}

export function notNull<T>(value: T | null): value is T {
    return value !== null
}

export function isDefined<T>(value: T | undefined): value is T {
    return value !== undefined
}

export function mapOrNull<T, R>(value: T | null | undefined, mapping: (t: T) => R | null): R | null {
    return (value !== null && value !== undefined && mapping(value)) || null
}

export function self<T>(value: T): T {
    return value
}

export function required<T>(value: T | null | undefined): T {
    if (value === null || value === undefined) {
        throw Error(`Value must not be null or undefined.`)
    }
    return value
}

export const doNothing = () => {}
