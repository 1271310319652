export interface Image {
    originalUrl: string
    previewUrl: string
    thumbnailUrl: string
}

export function parseImage(json: any): Image {
    const originalImageUrl = process.env.REACT_APP_BACKEND_URL + json.url
    return {
        originalUrl: originalImageUrl,
        previewUrl: `${originalImageUrl}?width=400`,
        thumbnailUrl: `${originalImageUrl}?width=100`,
    }
}
