import {fetchJson} from "./FetchJson";
import {parseShippingMethod, ShippingMethod} from "./ShippingMethod";
import {parsePaymentMethod, PaymentMethod} from "./PaymentMethod";

export interface OrderSettings {
    shippingMethods: ShippingMethod[]
    paymentMethods: PaymentMethod[]
}

export const getOrderSettings: Promise<OrderSettings> = fetchJson('/api/order-settings?populate=deep,3')
    .then((json) => {
        let data = json.data
        return {
            shippingMethods: (data.shippingMethods as any[]).map(parseShippingMethod),
            paymentMethods: (data.paymentMethods as any[]).map(parsePaymentMethod),
        }
    })
