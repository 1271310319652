import {Image, parseImage} from "./Images";
import {getNewsEntryThumbnailImage, parseNewsEntry} from "./News";

export interface SliderItemData {
    title: string
    feature: string | null
    image: Image
    itemUrl: string
}

export function parseSliderItem(json: any): SliderItemData | null {
    const newsEntry = json.newsEntry && parseNewsEntry(json.newsEntry)
    const newsEntryImage = newsEntry && getNewsEntryThumbnailImage(newsEntry)
    const motoType = json.motoType
    try {
        return {
            title: json.title || (newsEntry && newsEntry.title) || (motoType && motoType.title) || "",
            feature: json.subtitle,
            image: newsEntryImage || parseImage(motoType.sliderImage),
            itemUrl: json.link || (newsEntry && `news/${newsEntry.id}`) || `catalog?category=motorcycles&mt=${motoType.title}`,
        }
    } catch (error) {
        return null
    }
}
