export interface Coordinates {
    latitude: number
    longitude: number
}

export function parseCoordinates(json: any): Coordinates {
    return {
        latitude: json.latitude,
        longitude: json.longitude,
    }
}
