import "./News.css"
import {NewsContent} from "../../../data/PageContent";
import {useEffect, useState} from "react";
import {getNews, getNewsEntryThumbnailImage, NewsEntry} from "../../../data/News";
import Loader from "../../primitives/Loader";
import {associate} from "../../../functional/Arrays";
import {useNavigate} from "react-router-dom";

function NewsView(props: {
    content: NewsContent
}) {
    const [selectedCategory, setCategory] = useState<string | null>(null)
    const [newsEntries, setNewsEntries] = useState<NewsEntry[] | null>(null)
    const navigate = useNavigate()
    const newsByCategory = associate(newsEntries || [], (entry) => entry.category, (entry) => entry)
    const news = (selectedCategory && newsByCategory.get(selectedCategory)) || newsEntries
    useEffect(() => {
        getNews().then(setNewsEntries)
    }, [])
    return <div>
        <div className="l-container l-tabs tabs">
            <div className="tab">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a className={selectedCategory || 'active'} onClick={() => setCategory(null)}>
                    Все
                </a>
            </div>
            {Array.from(newsByCategory.keys()).map((category, index) => <div key={index} className="tab">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a className={(selectedCategory === category && 'active') || ''} onClick={() => setCategory(category)}>
                    {category}
                </a>
            </div>)}
        </div>
        <div className="news">
            <div className="l-container">
                <ul className="l-news">
                    {!news && <Loader/>}
                    {news && news.map((entry, index) => <li key={index} className="news_teaser">
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a onClick={() => navigate(`/news/${entry.id}`)}>
                            <time className="news_teaser_date">{entry.publishDate.toLocaleDateString()}</time>
                            <NewsEntryImage entry={entry}/>
                            <h2>{entry.title}</h2>
                        </a>
                    </li>)}
                </ul>
            </div>
        </div>
    </div>;
}

function NewsEntryImage(props: {
    entry: NewsEntry
}) {
    const thumbnailImage = getNewsEntryThumbnailImage(props.entry)
    if (thumbnailImage) {
        return <img src={thumbnailImage.previewUrl} alt="" className="NewsEntryImage"/>
    } else {
        return <div className="NewsEntryImagePlaceholder"/>
    }
}

export default NewsView;
