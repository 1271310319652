import React from "react";
import {getPages} from "../data/Pages";
import {Route, Routes} from "react-router-dom";
import PageResolver from "./PageResolver";
import {NewsEntryView} from "./views/news/NewsEntryView";
import {Page404} from "./Page404";
import {PageLoading} from "./PageLoading";
import {CatalogCategory} from "../data/CatalogCategory";
import {ProductEntryView} from "./views/product/ProductEntryView";
import {LoginView} from "./auth/LoginView";
import {RegisterView} from "./auth/RegisterView";
import {ProfileView} from "./auth/ProfileView";
import {useData} from "../functional/Hooks";

function RoutesContainer() {
    const pages = useData(getPages)
    return <>
        {pages && <Routes>
            {pages.map((page) => <Route key={page.id} path={`/${page.slug}`}
                                        element={<PageResolver key={page.id} pageId={page.id}/>}/>)}
            <Route path='news/:id' element={<NewsEntryView/>}/>
            <Route path='motorcycles/:id' element={<ProductEntryView category={CatalogCategory.MOTORCYCLES}/>}/>
            <Route path='parts/:id' element={<ProductEntryView category={CatalogCategory.PARTS}/>}/>
            <Route path='accessories/:id' element={<ProductEntryView category={CatalogCategory.ACCESSORIES}/>}/>
            <Route path='login' element={<LoginView/>}/>
            <Route path='register' element={<RegisterView/>}/>
            <Route path='profile' element={<ProfileView/>}/>
            <Route path='*' element={<Page404/>}/>
        </Routes>}
        {!pages && <PageLoading/>}
    </>
}

export default RoutesContainer;
