import {Color, parseColor} from "./Color";
import {mapOrNull} from "../functional/Functions";

export interface MenuItem {
    title: string
    slug: string
    color: Color | null
}

export function parseMenuItem(json: any): MenuItem {
    let query = json.query
    let slug = json.page.slug
    return {
        title: json.title,
        slug: (query && `${slug}?${query}`) || slug,
        color: mapOrNull(json.color, parseColor),
    }
}
