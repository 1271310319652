import {MenuItem, parseMenuItem} from "./MenuItem";
import {fetchJson} from "./FetchJson";
import {Image, parseImage} from "./Images";

export interface GlobalSettings {
    header: string
    address: string
    brandLogoImageUrl: string
    partnerLogoUrls: string[]
    footer: string
    menuItems: MenuItem[]
}

export function getGlobalSettings(): Promise<GlobalSettings> {
    return fetchJson('/api/global-settings?populate=deep,3')
        .then((json) => {
            let data = json.data
            return {
                header: data.header,
                address: data.address,
                brandLogoImageUrl: parseImage(data.brandLogo).originalUrl,
                partnerLogoUrls: data.partnerLogos.map(parseImage).map((image: Image) => image.originalUrl),
                footer: data.footer,
                menuItems: (data.menuItems as any[]).map(parseMenuItem),
            }
        })
}
