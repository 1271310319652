import {
    ButtonContent,
    CartContent,
    CatalogContent,
    ContactsContent,
    CoverContent,
    HomePageContent,
    HtmlContent,
    ImagesGridContent,
    ImageWithTextContent,
    NewsContent,
    OrderContent,
    PageContent,
    SeparatedHtmlContent,
    ServicePreviewContent,
    ServiceTableContent
} from "../../data/PageContent";
import HomePageView from "./home/HomePageView";
import HtmlView from "./HtmlView";
import SeparatedHtmlView from "./SeparatedHtmlView";
import ServicePreviewView from "./ServicePreviewView";
import ServiceTableView from "./ServiceTableView";
import CatalogView from "./catalog/CatalogView";
import ContactsView from "./contacts/ContactsView";
import NewsView from "./news/NewsView";
import {ImagesGridView} from "./ImagesGridView";
import {ImageWithTextView} from "./ImageWithTextView";
import {ButtonView} from "./ButtonView";
import {CoverView} from "./CoverView";
import {CartView} from "./cart/CartView";
import {OrderView} from "./order/OrderView";

export function PageContentView(props: {
    content: PageContent
}) {
    if (props.content instanceof HomePageContent) {
        return <HomePageView
            content={props.content}
        />
    }
    if (props.content instanceof HtmlContent) {
        return <HtmlView
            content={props.content}
        />
    }
    if (props.content instanceof SeparatedHtmlContent) {
        return <SeparatedHtmlView
            content={props.content}
        />
    }
    if (props.content instanceof ServicePreviewContent) {
        return <ServicePreviewView
            content={props.content}
        />
    }
    if (props.content instanceof ServiceTableContent) {
        return <ServiceTableView
            content={props.content}
        />
    }
    if (props.content instanceof CatalogContent) {
        return <CatalogView
            content={props.content}
        />
    }
    if (props.content instanceof CartContent) {
        return <CartView
            content={props.content}
        />
    }
    if (props.content instanceof OrderContent) {
        return <OrderView
            content={props.content}
        />
    }
    if (props.content instanceof ContactsContent) {
        return <ContactsView
            content={props.content}
        />
    }
    if (props.content instanceof NewsContent) {
        return <NewsView
            content={props.content}
        />
    }
    if (props.content instanceof ImagesGridContent) {
        return <ImagesGridView
            content={props.content}
        />
    }
    if (props.content instanceof ImageWithTextContent) {
        return <ImageWithTextView
            content={props.content}
        />
    }
    if (props.content instanceof ButtonContent) {
        return <ButtonView
            content={props.content}
        />
    }
    if (props.content instanceof CoverContent) {
        return <CoverView
            content={props.content}
        />
    }
    return <div/>
}
