import {CatalogItem} from "./CatalogItems";
import {sum} from "../functional/Functions";
import {CartEntry} from "./CartEntry";
import {getCartEntryVariant} from "./Catalog";
import {Currency} from "./Currency";
import {CatalogCategory} from "./CatalogCategory";

export function roundedPrice(category: CatalogCategory, price: number, currency: Currency): number {
    let convertedPrice = price * currency.rate
    if (currency.code === "BYN") {
        if (category === CatalogCategory.MOTORCYCLES) {
            return Math.ceil(convertedPrice / 10) * 10
        } else {
            return Math.ceil(convertedPrice / 0.5) * 0.5
        }
    } else {
        return convertedPrice
    }
}

export function formattedPriceWithoutCurrency(item: CartEntry & CatalogItem, currency: Currency): string {
    let price = roundedPrice(item.category, getCartEntryVariant(item).price, currency)
    return withoutCurrency(price)
}

export function formattedLinePrice(item: CatalogItem & CartEntry, currency: Currency): string {
    return withCurrency(calculateLinePrice(item, currency), currency)
}

export function formattedTotalPrice(
    items: (CatalogItem & CartEntry)[],
    currency: Currency,
): string {
    let linePrice = items.map((item) => calculateLinePrice(item, currency)).reduce(sum)
    return withCurrency(linePrice, currency)
}

function withCurrency(price: number, currency: Currency): string {
    return `${withoutCurrency(price)} ${currency.code}`
}

function withoutCurrency(price: number): string {
    return `${price.toFixed(2)}`
}

function calculateLinePrice(item: CatalogItem & CartEntry, currency: Currency): number {
    return roundedPrice(item.category, getCartEntryVariant(item).price, currency) * item.count
}
