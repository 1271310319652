import "react-responsive-carousel/lib/styles/carousel.min.css";
import {Carousel} from "react-responsive-carousel";
import {SliderItemData} from "../../../data/SliderItemData";
import {DoubleArrow} from "@mui/icons-material";
import {Link} from "react-router-dom";

export function HomeSlider(props: {
    items: SliderItemData[],
}) {
    const isWideScreen = (window.innerWidth / 1024) > 1
    const numberOfItemsWithinViewport = (!isWideScreen && 1) || Math.min(3, props.items.length)
    const centerSlidePercentage = (isWideScreen && 100 / numberOfItemsWithinViewport) || 100
    return <div className="l-mainSlider mainSlider">
        <Carousel className="models"
                  autoPlay={false}
                  showArrows={true}
                  showThumbs={false}
                  showStatus={false}
                  emulateTouch={false}
                  infiniteLoop={true}
                  showIndicators={false}
                  centerMode={true}
                  preventMovementUntilSwipeScrollTolerance={true}
                  centerSlidePercentage={centerSlidePercentage}
                  renderArrowPrev={(onClickHandler, hasPrev) =>
                      hasPrev && <div className="owl-prev" onClick={onClickHandler}><p>&larr;</p></div>
                  }
                  renderArrowNext={(onClickHandler, hasNext, label) =>
                      hasNext && <div className="owl-next" onClick={onClickHandler}><p>&rarr;</p></div>
                  }
        >
            {props.items.map((itemData, index) => <SliderItem key={index} data={itemData}/>)}
        </Carousel>
    </div>
}

function SliderItem(props: {
    data: SliderItemData,
}) {
    return <div className="mainSlider_item">
        <div className="mainSlider_item_content">
            <Link className="heading" to={props.data.itemUrl}>
                <h3>{props.data.title}</h3>
                {props.data.feature && <span className="feature">
                    <DoubleArrow/>
                    {props.data.feature}
                </span>}
            </Link>
            <Link to={props.data.itemUrl} className="button button-clean button-teaser">подробнее</Link>
        </div>
        <div className="slide_bg">
            <div className="mainSlider_item_overlay"/>
            <div className="slide_bg_img" style={{backgroundImage: `url('${props.data.image.originalUrl}')`}}/>
            <img src={props.data.image.originalUrl} alt=""/>
        </div>
    </div>
}
