import {
    ButtonContent,
    CartContent,
    CatalogContent,
    ContactsContent,
    CoverContent,
    HomePageContent,
    HtmlContent,
    ImagesGridContent,
    ImageWithTextContent,
    NewsContent,
    OrderContent,
    PageContent,
    SeparatedHtmlContent,
    ServicePreviewContent,
    ServiceTableContent
} from "./PageContent";
import {parseCoordinates} from "./Coordinates";
import {parseImage} from "./Images";
import {parseSliderItem} from "./SliderItemData";
import {parseProduct} from "./Product";
import {CatalogCategory} from "./CatalogCategory";
import {notNull} from "../functional/Functions";

export function parsePageContent(jsonPage: any): PageContent {
    switch (jsonPage.__component) {
        case "content.home":
            return new HomePageContent(
                jsonPage.teaserTitle,
                jsonPage.teaserVideoUrl,
                parseImage(jsonPage.teaserImage),
                jsonPage.introText.title,
                jsonPage.introText.description,
                (jsonPage.motorcycles as any[]).map(jsonItem => parseProduct(jsonItem, CatalogCategory.MOTORCYCLES)),
                (jsonPage.sliderItems as any[]).map(parseSliderItem).filter(notNull),
            )
        case "content.html":
            return parseHtmlContent(jsonPage)
        case "content.separated-html":
            return new SeparatedHtmlContent(
                jsonPage.asideTitle,
                jsonPage.asideDescription,
                (jsonPage.body as any[]).map(parseHtmlContent)
            )
        case "content.service-preview":
            return new ServicePreviewContent(
                jsonPage.asideTitle,
                jsonPage.asideDescription,
                jsonPage.targetPage.slug,
                parseImage(jsonPage.image),
            )
        case "content.service-table":
            return new ServiceTableContent(
                (jsonPage.items as any[]).map(parseServiceTableItem)
            )
        case "content.catalog":
            return new CatalogContent()
        case "content.cart":
            return new CartContent()
        case "content.order":
            return new OrderContent()
        case "content.contacts":
            return new ContactsContent(
                jsonPage.text,
                parseCoordinates(jsonPage.coordinates),
                (jsonPage.phones as string).split('\n'),
                (jsonPage.viber as string).split('\n'),
                (jsonPage.emails as string).split('\n'),
                jsonPage.extraText,
                parseImage(jsonPage.mapMarkerIcon).originalUrl,
            )
        case "content.news":
            return new NewsContent()
        case "content.images-grid":
            return new ImagesGridContent(
                (jsonPage.images as any[]).map(parseImage),
            )
        case "content.image-with-text":
            return new ImageWithTextContent(
                jsonPage.text,
                parseImage(jsonPage.image),
                jsonPage.imageAlignment,
            )
        case "content.button":
            return new ButtonContent(
                jsonPage.title,
                parseMediaUrl(jsonPage) || jsonPage.externalUrl,
            )
        case "content.cover":
            return new CoverContent(
                parseImage(jsonPage.image),
            )
        default:
            return new PageContent()
    }
}

function parseMediaUrl(json: any): string | null {
    return json.media && process.env.REACT_APP_BACKEND_URL + json.media.url
}

function parseHtmlContent(json: any): HtmlContent {
    return new HtmlContent(
        json.title,
        json.body,
    )
}

function parseServiceTableItem(json: any): ServiceTableContent.Item {
    return new ServiceTableContent.Item(
        json.title,
        json.description,
    )
}
