import {useAppSelector} from "../redux/Hooks";
import {useData} from "../functional/Hooks";
import {Currency} from "../data/Currency";
import {getCatalogSettings} from "../data/CatalogSettings";
import {useDispatch} from "react-redux";
import {setPreferredCurrencyCode} from "../redux/UserSlice";

export function usePreferredCurrency(): [Currency | null, (preferredCurrencyCode: string) => void] {
    const dispatch = useDispatch()
    const catalogSettings = useData(() => getCatalogSettings)
    const preferredCurrencyCode = useAppSelector(state => state.user.preferredCurrencyCode)
    let preferredCurrency: Currency | null
    if (catalogSettings) {
        preferredCurrency = [catalogSettings.primaryCurrency, ...catalogSettings.secondaryCurrencies]
            .find((currency) => currency.code === preferredCurrencyCode) || catalogSettings.primaryCurrency
    } else {
        preferredCurrency = null
    }
    return [preferredCurrency, (preferredCurrencyCode) => dispatch(setPreferredCurrencyCode(preferredCurrencyCode))]
}
