import {OrderContent} from "../../../data/PageContent";
import React from "react";
import Loader from "../../primitives/Loader";
import {useCartItems} from "../../../hooks/CartItems";
import {useData} from "../../../functional/Hooks";
import {getOrderSettings} from "../../../data/OrderSettings";
import {OrderFormContainer} from "./OrderFormContainer";

export function OrderView(props: {
    content: OrderContent
}) {
    const cartItems = useCartItems()
    const orderSettings = useData(() => getOrderSettings)
    if (cartItems && orderSettings) {
        return <>
            <OrderFormContainer cartItems={cartItems} orderSettings={orderSettings}/>
        </>
    } else {
        return <>
            <Loader/>
        </>
    }
}
