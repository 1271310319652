import "./PageResolver.css"
import {useEffect, useState} from "react";
import {getPageDetails, PageDetails} from "../data/PageDetails";
import PageHeadingView from "./views/PageHeadingView";
import {PageContentView} from "./views/PageContentView";
import {PageHeadingPlaceholder} from "./PageHeadingPlaceholder";
import {PageLoading} from "./PageLoading";
import {MetaData} from "./MetaData";

function PageResolver(props: {
    pageId: string
}) {
    const [pageDetails, setPageDetails] = useState<PageDetails | null>(null);
    useEffect(() => {
        getPageDetails(props.pageId).then(setPageDetails)
    }, [props]);
    return <>
        {pageDetails && <PageContent data={pageDetails}/>}
        {!pageDetails && <PageLoading/>}
    </>
}

function PageContent(props: {
    data: PageDetails,
}) {
    const PageHeading = () => {
        if (props.data.heading) {
            return <PageHeadingView pageHeading={props.data.heading}/>
        } else if (!props.data.content.some(content => content.assumesTransparentNavBar)) {
            return <PageHeadingPlaceholder/>
        } else {
            return <div/>
        }
    }
    return <>
        <MetaData title={props.data.title}/>
        <PageHeading/>
        {props.data.content.map((pageContent, index) => <PageContentView key={index} content={pageContent}/>)}
    </>
}

export default PageResolver;
