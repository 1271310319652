import "./AppContent.css"
import {GlobalSettings} from "../data/GlobalSettings";
import TopBar from "./common/TopBar";
import NavBar from "./common/NavBar";
import RoutesContainer from "./RoutesContainer";
import Partners from "./common/Partners";
import Footer from "./common/Footer";
import React from "react";

export function AppContent(props: {
    globalSettings: GlobalSettings,
}) {
    return <div className="AppContent">
        <TopBar
            headerText={props.globalSettings.header}
            addressText={props.globalSettings.address}
        />

        <NavBar
            menuItems={props.globalSettings.menuItems}
            brandLogoImageUrl={props.globalSettings.brandLogoImageUrl}
        />

        <div className="Flex">
            <RoutesContainer
            />
        </div>

        <Partners
            imageUrls={props.globalSettings.partnerLogoUrls}
        />
        <Footer
            footerText={props.globalSettings.footer}
        />
    </div>
}
