import {Link} from "react-router-dom";
import {Product} from "../../../data/Product";

export function HomeMotorcycleView(props: {
    motorcycle: Product,
}) {
    const detailsUrl = `motorcycles/${props.motorcycle.id}`
    return <section className="mainModel l-container-column is-text-center">
        <h4 className="mainModel_subHeader">{props.motorcycle.productInfo?.storyTitle}</h4>
        <h2 className="mainModel_header">{props.motorcycle.title}</h2>
        <a className="mainModel_features feature text-orange" href={detailsUrl}>
            <h4>характеристики</h4>
        </a>
        <a className="mainModel_cover" href={detailsUrl}>
            <img
                src={props.motorcycle.variants[0].images[0]?.originalUrl}
                className="lazyload"
                alt=""/>
        </a>
        <Link to={detailsUrl} className="button button-teaser button-clean-black">Подробнее о модели</Link>
    </section>
}
