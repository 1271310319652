import {MotoType} from "./MotoTypes";
import {AccessoryType} from "./AccessoryTypes";
import {PartType} from "./PartTypes";

export abstract class Filter {

    abstract criteria(): string[]
}

export class ArrayInFilter extends Filter {

    readonly values: any[]
    readonly selector: string

    constructor(
        values: any[],
        selector: string,
    ) {
        super()
        this.values = values
        this.selector = selector
    }

    criteria(): string[] {
        return this.values.map((value, index) => `${this.selector}[$in][${index}]=${value}`)
    }
}

export function arrayOrFilter(filters: Filter[]): Filter {
    return new class extends Filter {
        criteria(): string[] {
            return filters.flatMap((filter, index) => filter.criteria().map((criteria) => `[$or][${index}]${criteria}`))
        }
    }()
}

export function arrayAndFilter(filters: Filter[]): Filter {
    return new class extends Filter {
        criteria(): string[] {
            return filters.flatMap((filter, index) => filter.criteria().map((criteria) => `[$and][${index}]${criteria}`))
        }
    }()
}

export function idsFilter(ids: any[]): Filter {
    return new ArrayInFilter(ids, "[id]")
}

export function catalogTextFilter(value: string): Filter {
    return arrayOrFilter([
        catalogTitleFilter(value),
        catalogSkuFilter(value),
    ])
}

export function accessoryTypesFilter(types: AccessoryType[]): Filter {
    return arrayOrFilter(types.map(accessoryTypeFilter))
}

export function partTypesFilter(types: PartType[]): Filter {
    return arrayOrFilter(types.map(partTypeFilter))
}

export function motoTypeFilter(type: MotoType): Filter {
    return eqFilter("[type][title]", type)
}

function accessoryTypeFilter(type: AccessoryType): Filter {
    return arrayOrFilter(
        [
            eqFilter("[type][title]", type.title),
            ...(type.filter?.split(',').map(catalogTitleFilter) || [])
        ]
    )
}

function partTypeFilter(type: PartType): Filter {
    return eqFilter("[type][title]", type.title)
}

function catalogTitleFilter(value: string): Filter {
    return eqFilter("[title][$containsi]", value)
}

function catalogSkuFilter(value: string): Filter {
    return eqFilter("[variants][sku][$containsi]", value)
}

function eqFilter(
    selector: string,
    value: string,
): Filter {
    return new class extends Filter {
        criteria(): string[] {
            return [`${selector}=${value}`]
        }
    }()
}
