export function FailureSubmission(props: {
    onRetry: () => void,
}) {
    return <div className="Flex Center">
        <h2 style={{color: "red"}}>Не удалось оформить заказ, попробуйте позже</h2>
        <br/>
        {/* TODO: Link will not work since we need to navigate to the current route */}
        {/* eslint-disable-next-line */}
        <a href="#" onClick={props.onRetry}>К корзине</a>
    </div>
}
