import {fetchJson} from "./FetchJson";
import {isDefined, notNull} from "../functional/Functions";

export interface DeliveryOffice {
    id: number,
    label: string,
}

export function getDeliveryOffices(apiCollectionName: string, searchQuery?: string): Promise<DeliveryOffice[]> {
    let query = (searchQuery && ('?' + [
        "name",
        "city",
        "region",
        "address",
    ].map((field, index) => `filters[$or][${index}][${field}][$containsi]=${searchQuery}`).join('&'))) || ""
    return fetchJson(`/api/${apiCollectionName}${query}`)
        .then((json) => {
            return json.data.map(parseDeliveryOffice)
        })
}

function parseDeliveryOffice(json: any): DeliveryOffice {
    return {
        id: json.id,
        label: [
            json.name,
            json.address,
            json.city,
            json.region,
        ].filter(isDefined).filter(notNull).join(", "),
    }
}
