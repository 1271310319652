import {useAppDispatch} from "../../../redux/Hooks";
import {createOrder} from "../../../data/Orders";
import React, {useState} from "react";
import {clear} from "../../../redux/CartSlice";
import Loader from "../../primitives/Loader";
import {SuccessSubmission} from "./SuccessSubmission";
import {FailureSubmission} from "./FailureSubmission";
import {OrderForm} from "./OrderForm";
import {CartEntry} from "../../../data/CartEntry";
import {CatalogItem} from "../../../data/CatalogItems";
import {OrderSettings} from "../../../data/OrderSettings";
import {SubmitOrder} from "./SubmitOrder";
import {useData} from "../../../functional/Hooks";
import {getCatalogSettings} from "../../../data/CatalogSettings";

enum SubmissionState {
    None,
    InProgress,
    Success,
    Failure,
}

export function OrderFormContainer(props: {
    cartItems: (CartEntry & CatalogItem)[],
    orderSettings: OrderSettings,
}) {
    const dispatch = useAppDispatch()
    const [submission, setSubmission] = useState<SubmissionState>(SubmissionState.None)
    const catalogSettings = useData(() => getCatalogSettings)
    if (catalogSettings) {
        const submitOrder: SubmitOrder = (shippingMethod, deliveryOffice, paymentMethod, contacts, comments) => {
            setSubmission(SubmissionState.InProgress)
            createOrder(
                props.cartItems,
                catalogSettings,
                shippingMethod,
                deliveryOffice,
                paymentMethod,
                contacts,
                comments,
            ).then(() => {
                setSubmission(SubmissionState.Success)
                dispatch(clear())
            }).catch(() => setSubmission(SubmissionState.Failure))
        }
        return <>
            {submission === SubmissionState.None &&
                <OrderForm orderSettings={props.orderSettings} onSubmit={submitOrder}/>}
            {submission === SubmissionState.InProgress && <Loader/>}
            {submission === SubmissionState.Success && <SuccessSubmission/>}
            {submission === SubmissionState.Failure &&
                <FailureSubmission onRetry={() => setSubmission(SubmissionState.None)}/>}
        </>
    } else {
        return <Loader/>
    }
}
