export interface PaymentMethod {
    id: number
    title: string
}

export function parsePaymentMethod(json: any): PaymentMethod {
    return {
        id: json.id,
        title: json.title,
    }
}
