import CheckBox from "../primitives/CheckBox";
import React from "react";

export function CheckBoxFilterView<T>(props: {
    label: string
    allValues: T[]
    selectedValues: T[]
    onAddValue: (value: T) => void
    onUpdateValues: (values: T[]) => void
    valueLabel: (value: T) => string,
    extraComponent?: (value: T) => React.ReactNode,
}) {
    return <>
        <h2>{props.label}</h2>
        {props.allValues.map((value, index) =>
            <div key={`${props.label}-${index}`}>
                <CheckBox
                    id={`${props.label}-${index}`}
                    label={props.valueLabel(value)}
                    checked={props.selectedValues.includes(value)}
                    onChange={() => {
                        if (props.selectedValues.includes(value)) {
                            props.onUpdateValues(props.selectedValues.filter((it) => it !== value))
                        } else {
                            props.onAddValue(value)
                            props.onUpdateValues(props.selectedValues.concat([value]))
                        }
                    }}
                />
                {props.selectedValues.includes(value) && props.extraComponent && props.extraComponent(value)}
            </div>
        )}
    </>
}
