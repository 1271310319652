import AsyncSelect from "react-select/async";
import React from "react";
import {DeliveryOffice, getDeliveryOffices} from "../../../data/DeliveryOffice";

export function DeliveryOfficeSelector(props: {
    collectionName: string,
    selected: DeliveryOffice | null,
    onChanged: (office: DeliveryOffice) => void,
}) {
    const getSelectOptions = (inputValue: string) =>
        getDeliveryOffices(props.collectionName, inputValue).then((offices) => {
            return offices.map((office) => {
                return {
                    value: office,
                    label: office.label,
                }
            })
        })
    return <AsyncSelect
        cacheOptions
        defaultOptions
        loadOptions={getSelectOptions}
        onChange={(option) => {
            if (option) {
                props.onChanged(option.value)
            }
        }}
        styles={{
            control: (baseStyles, state) => ({
                ...baseStyles,
                borderWidth: "2px",
                borderColor: "#f0f0f0",
                ":hover": {
                    borderColor: "#f0f0f0",
                },
                borderStyle: "solid",
                borderRadius: "0",
            }),
            container: (baseStyles) => ({
                ...baseStyles,
                maxWidth: "400px",
                padding: "0px",
            }),
            valueContainer: (baseStyles) => ({
                ...baseStyles,
                padding: "10px",
            }),
            indicatorSeparator: () => ({})
        }}
        noOptionsMessage={() => "Не найдено"}
        loadingMessage={() => "Поиск..."}
        placeholder={"Выберите отделение"}
    />
}
