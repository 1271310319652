import "./ProductVariantSelector.css"
import React from "react";
import {CatalogItemVariant} from "../../../data/CatalogItemVariant";

export function ProductVariantSelector(props: {
    variants: CatalogItemVariant[],
    selectedVariant: CatalogItemVariant,
    setVariant: (variation: number) => void,
}) {
    const displayableVariants = props.variants.filter((variant) => variant.qualifier || variant.color)
    const VariantItem = (variant: CatalogItemVariant, index: number) => {
        let itemStyle = "ProductVariantSelectorItem " + (variant.id === props.selectedVariant.id && "Active") || ""
        return <li key={index} className={itemStyle}
                   onClick={() => props.setVariant(index)}
                   title={`Артикул ${variant.sku}`}>
            <div className="ProductVariantSelectorItemColor"
                 style={{backgroundColor: variant.color?.colorHex || "transparent"}}/>
            {variant.qualifier && <div className="ProductVariantSelectorItemTitle">
                {variant.qualifier}
            </div>}
        </li>
    }
    return <>
        {displayableVariants.length > 0 && <ul className="ProductVariantSelector">
            <li><h4>Варианты</h4></li>
            {displayableVariants.map(VariantItem)}
        </ul>}
    </>
}
