import {BeatLoader} from "react-spinners";
import React from "react";

function Loader() {
    return <div className="Flex Center">
        <BeatLoader/>
    </div>
}

export default Loader;
