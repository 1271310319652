import {useEffect, useState} from "react";
import {CartEntry} from "../data/CartEntry";
import {CatalogItem, getItemsOfCategory} from "../data/CatalogItems";
import {useUserToken} from "./User";
import {associate, combine} from "../functional/Arrays";
import {sameAsCartItem} from "../redux/CartSlice";
import {CartItem} from "../data/CartItem";
import {useAppSelector} from "../redux/Hooks";
import {idsFilter} from "../data/Filters";

export function useCartItems() {
    const cartEntries = useAppSelector(state => state.cart.entries)
    const [cartItems, setCartItems] = useState<(CartEntry & CatalogItem)[] | null>(null)
    const userToken = useUserToken()
    useEffect(() => {
        loadCartItems(cartEntries).then(setCartItems)
    }, [cartEntries, userToken])
    return cartItems
}

function loadCartItems(cartEntries: CartEntry[]): Promise<(CartEntry & CatalogItem)[]> {
    return loadCatalogItems(cartEntries).then((items) => combine(cartEntries, items, sameAsCartItem))
}

function loadCatalogItems(cartItems: CartItem[]): Promise<CatalogItem[]> {
    let cartItemsByCategory = associate(cartItems, (item) => item.category, (item) => item.id)
    let catalogItems = Array.from(cartItemsByCategory.entries()).map(([category, ids]) => getItemsOfCategory(category, idsFilter(ids)))
    return Promise.all(catalogItems).then((it) => Array.from(it).map(page => page.items).flat())
}
