import {notNull} from "./Functions";

export type NonEmptyArray<T> = [T, ...T[]]

export function associate<K, V, R>(array: V[], keySelector: (v: V) => K, valueProducer: (v: V) => R): Map<K, R[]> {
    let result = new Map<K, R[]>()
    array.forEach((value) => {
        let key = keySelector(value)
        let currentValue = result.get(key) || []
        let newValue = currentValue.concat([valueProducer(value)])
        result.set(key, newValue)
    })
    return result
}

export function combine<T, R>(tArray: T[], rArray: R[], predicate: (t: T) => (r: R) => boolean): (T & R)[] {
    return tArray.map((t) => {
        let r = rArray.find(predicate(t))
        return (r && {...t, ...r}) || null
    }).filter(notNull)
}

export function wrapToArray<T>(value: T | null | undefined): T[] {
    if (value) {
        return [value]
    } else {
        return []
    }
}
