import {HtmlContent} from "../../data/PageContent";
import parse from 'html-react-parser';

function HtmlView(props: {
    content: HtmlContent
}) {
    return <div className="l-container">
        <div className="centered-content text">
            {parse(props.content.body)}
        </div>
    </div>;
}

export default HtmlView;
