import {fetchJson} from "./FetchJson";
import {CatalogCategory} from "./CatalogCategory";
import {Filter} from "./Filters";
import {CatalogItemVariant, parseCatalogItemVariant} from "./CatalogItemVariant";
import {NonEmptyArray} from "../functional/Arrays";
import {mapOrNull} from "../functional/Functions";

export interface CatalogItem {
    id: number
    title: string
    category: CatalogCategory
    type: string | null
    variants: NonEmptyArray<CatalogItemVariant>
}

export interface CatalogPage {
    items: CatalogItem[]
    pageNumber: number
    pageCount: number
}

export function getItemsOfCategory(
    category: CatalogCategory,
    filter: Filter,
    pageNumber: number = 1,
): Promise<CatalogPage> {
    let queryParams = filter.criteria().map((criteria) => `filters` + criteria)
        .concat([`pagination[page]=${[pageNumber]}`, "populate=deep"])
        .join('&')
    return fetchJson(`/api/${category}?${queryParams}`)
        .then((json) => {
            return {
                items: json.data.map((jsonItem: any) => parseCatalogItem(jsonItem, category)),
                pageNumber: json.meta.pagination.page,
                pageCount: json.meta.pagination.pageCount,
            } as CatalogPage
        })
}

export function searchCatalog(
    filter: Filter,
    pageNumber: number = 1,
): Promise<CatalogPage> {
    let queryParams = filter.criteria().map((criteria) => `filters` + criteria)
        .concat([`pagination[page]=${[pageNumber]}`, "populate=deep"])
        .join('&')
    return fetchJson(`/api/catalog?${queryParams}`)
        .then((json) => {
            return {
                items: json.data.map((jsonItem: any) => parseFoundCatalogItem(jsonItem)),
                pageNumber: json.meta.pagination.page,
                pageCount: json.meta.pagination.pageCount,
            } as CatalogPage
        })
}

export function catalogItemLink(item: {
    id: number
    category: CatalogCategory
}) {
    switch (item.category) {
        case CatalogCategory.MOTORCYCLES:
            return `/motorcycles/${item.id}`
        case CatalogCategory.PARTS:
            return `/parts/${item.id}`
        case CatalogCategory.ACCESSORIES:
            return `/accessories/${item.id}`
    }
}

export function parseCatalogItem(json: any, category: CatalogCategory): CatalogItem {
    return {
        id: json.id,
        title: json.title,
        category: category,
        type: mapOrNull(json.type, (type) => type.title),
        variants: parseCatalogItemVariants(json),
    }
}

function parseFoundCatalogItem(json: any): CatalogItem {
    return {
        id: json.id,
        title: json.title,
        category: json.category,
        type: mapOrNull(json.type, (type) => type.title),
        variants: parseCatalogItemVariants(json),
    }
}

function parseCatalogItemVariants(json: any): NonEmptyArray<CatalogItemVariant> {
    let variants = (json.variants as any[])?.map(parseCatalogItemVariant)
    return [variants[0], ...variants.slice(1)]
}
