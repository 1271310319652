import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {UserToken} from "../data/User";

interface UserState {
    token: UserToken | null,
    preferredCurrencyCode: string | null,
}

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        token: null,
    } as UserState,
    reducers: {
        setToken: (state: UserState, action: PayloadAction<UserToken>) => {
            state.token = action.payload
        },
        removeToken: (state: UserState) => {
            state.token = null
        },
        setPreferredCurrencyCode: (state: UserState, action: PayloadAction<string>) => {
            state.preferredCurrencyCode = action.payload
        },
    },
})

export const {
    setToken,
    removeToken,
    setPreferredCurrencyCode,
} = userSlice.actions
