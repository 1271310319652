function Partners(props: {
    imageUrls: string[]
}) {
    return <div className="partners">
        <div className="l-container">
            <div className="partners__content">
                {props.imageUrls.map((url, index) => <img key={index} src={url} alt={index.toString()}/>)}
            </div>
        </div>
    </div>;
}

export default Partners;
