import parse from "html-react-parser";
import "./ProductView.css"
import {CatalogSettings} from "../../../data/CatalogSettings";
import {PriceView} from "../PriceView";
import {Link, useSearchParams} from "react-router-dom";
import {Fancybox} from "@fancyapps/ui";
import {mapOrNull} from "../../../functional/Functions";
import {Product} from "../../../data/Product";
import {Image} from "../../../data/Images";
import {AddShoppingCartOutlined, RemoveShoppingCartOutlined} from "@mui/icons-material";
import {CatalogCategory, categoryDisplayValue, categoryTypeKey} from "../../../data/CatalogCategory";
import {useCartItemControl} from "../../../redux/CartSlice";
import {CartItem} from "../../../data/CartItem";
import React from "react";
import {ProductSpecsView} from "./ProductSpecsView";
import {CatalogItemVariant} from "../../../data/CatalogItemVariant";
import {ProductVariantSelector} from "./ProductVariantSelector";
import {MetaData} from "../../MetaData";
import {CurrencySelector} from "../currency/CurrencySelector";
import {usePreferredCurrency} from "../../../hooks/Currency";

export function ProductView(props: {
    category: CatalogCategory,
} & Product & CatalogSettings) {
    const [searchParams, setSearchParams] = useSearchParams()
    const variants = props.variants
    const selectedVariant = variants[parseInt(searchParams.get("variant") || "0")]
    const setVariant = (variation: number) => setSearchParams((params) => {
        params.set("variant", variation.toString())
        return params
    })
    const allCurrencies = [props.primaryCurrency, ...props.secondaryCurrencies]
    const [preferredCurrency, setPreferredCurrency] = usePreferredCurrency()
    const selectedCurrency = preferredCurrency || props.primaryCurrency
    return <>
        <MetaData
            title={`${props.title} (артикул ${selectedVariant.sku})`}
            description={`${props.productInfo?.storyTitle || ''} ${props.productInfo?.storyText || ''}`}
            extraKeywords={[props.title, selectedVariant.sku]}
        />
        <header className="l-container">
            <div className="l-productHeader productHeader">
                <div>
                    <div className="ProductViewLinks">
                        <Link to="/catalog">Каталог</Link>
                        <div>&nbsp;&raquo;&nbsp;</div>
                        <Link to={`/catalog?category=${props.category}`}>{categoryDisplayValue(props.category)}</Link>
                        {props.type && <>
                            <div>&nbsp;&raquo;&nbsp;</div>
                            <Link
                                to={`/catalog?category=${props.category}&${categoryTypeKey(props.category)}=${props.type}`}>{props.type}</Link>
                        </>}
                    </div>
                    <div className="productHeader_sku"><strong>Артикул: {selectedVariant.sku}</strong></div>
                    <h1>{props.title}</h1>
                </div>
                <div className="productHeader_action">
                    <div className="productHeader_pricearea">
                        <div className="productHeader_status">
                            {selectedVariant.inStock && <p style={{color: "green"}}> В наличии</p>}
                            {!selectedVariant.inStock && <p style={{color: "red"}}>Под заказ</p>}
                        </div>
                        <div className="ProductViewCurrencySelector">
                            <CurrencySelector
                                currencies={allCurrencies}
                                selectedCurrency={selectedCurrency}
                                setCurrency={index => setPreferredCurrency(allCurrencies[index].code)}
                            />
                        </div>
                        <div className="productHeader_price">
                            <PriceView
                                catalogCategory={props.category}
                                catalogItemVariant={selectedVariant}
                                catalogSettings={props}
                                selectedCurrency={selectedCurrency}
                            />
                        </div>
                    </div>
                    {React.createElement(CartButton, {...props, variantId: selectedVariant.id})}
                </div>
            </div>
        </header>
        <div id="details" className="l-container">
            <div className="l-productDetails productDetails">
                <div className="productDetails_desc">
                    <h2>{props.productInfo?.storyTitle}</h2>
                    <div className="ProductStoryText">{mapOrNull(props.productInfo?.storyText, parse)}</div>
                    <ProductVariantSelector variants={variants} selectedVariant={selectedVariant}
                                            setVariant={setVariant}/>
                </div>
                <ProductImages
                    selectedVariant={selectedVariant}
                    allVariants={variants}
                />
            </div>
        </div>
        <ProductSpecsView productSpecs={props.productSpecs}/>
    </>
}

export function ProductImages(props: {
    selectedVariant: CatalogItemVariant,
    allVariants: CatalogItemVariant[],
}) {
    Fancybox.bind('[data-fancybox="product-gallery"]')
    let images: Image[]
    if (props.selectedVariant.images.length > 0) {
        images = props.selectedVariant.images
    } else {
        images = props.allVariants.find((variant) => variant.images.length > 0)?.images || []
    }
    if (images.length === 0) return <div/>
    return <div className="productDetails_cover">
        <CoverImage image={images[0]} fancyBoxId="product-gallery"/>
        <div className="productDetails_cover_thumbs">
            {images.slice(1).map((image, index) =>
                <a key={index}
                   href={image.originalUrl}
                   data-fancybox="product-gallery"
                   className="productDetails_cover_thumb">
                    <img src={image.thumbnailUrl} alt=""/>
                </a>)}
        </div>
    </div>
}

function CoverImage(props: {
    image: Image,
    fancyBoxId: string,
}) {
    return <a href={props.image.originalUrl} data-fancybox={props.fancyBoxId}>
        <img src={props.image.previewUrl} alt=""/>
    </a>
}

function CartButton(props: CartItem) {
    const [addToCart, removeFromCart, isItemInCart] = useCartItemControl(props)
    const className = `button ${(isItemInCart && "button-clean-orange") || ""}`
    const onClick = (isItemInCart && removeFromCart) || addToCart
    return <div className={className} style={{paddingTop: "0.75rem", height: "64px"}} onClick={onClick}>
        {!isItemInCart && <AddShoppingCartOutlined/>}
        {isItemInCart && <RemoveShoppingCartOutlined/>}
    </div>
}
