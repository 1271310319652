export interface Color {
    id: number
    name: string
    colorHex: string
}

export function parseColor(json: any): Color | null {
    return {
        id: json.id,
        name: json.name,
        colorHex: json.color,
    }
}
