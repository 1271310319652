import {ImagesGridContent} from "../../data/PageContent";

export function ImagesGridView(props: {
    content: ImagesGridContent,
}) {
    return <div className="grid grid--with-padding">
        {props.content.images.map((image, index) =>
            <a key={index} href={image.originalUrl} data-fancybox="">
                <img
                    src={image.previewUrl}
                    alt=""
                    className="lazyload"/>
            </a>)}
    </div>
}
