import "./ProfileView.css"
import {PageHeadingPlaceholder} from "../PageHeadingPlaceholder";
import React, {useEffect} from "react";
import {useUserProfile} from "../../hooks/User";
import {useNavigate} from "react-router-dom";
import Loader from "../primitives/Loader";
import {OutlinedButton} from "../primitives/OutlinedButton";
import {useDispatch} from "react-redux";
import {removeToken} from "../../redux/UserSlice";
import {Order} from "../../data/Order";
import Collapsible from "react-collapsible";
import {ArrowDropDown, ArrowDropUp} from "@mui/icons-material";
import {catalogItemLink} from "../../data/CatalogItems";
import {getVariantQualifiers} from "../../data/Catalog";

export function ProfileView() {
    const userProfile = useUserProfile()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const onLogout = () => dispatch(removeToken())
    useEffect(() => {
        if (userProfile === null) {
            navigate(`/`, {
                replace: true,
            })
        }
    }, [navigate, userProfile])
    return <>
        <PageHeadingPlaceholder/>
        {!userProfile && <Loader/>}
        {userProfile && <div className="Profile Flex">
            <br/>
            <h1>Профиль пользователя</h1>
            <br/>
            <br/>
            <p>{`ФИО: ${userProfile.name}`}</p>
            <p>{`Email: ${userProfile.email}`}</p>
            <p>{`Номер телефона: ${userProfile.phone}`}</p>
            <br/>
            {userProfile.discount > 0 && <p>{`Ваша персональная скидка: ${userProfile.discount}%`}</p>}
            <br/>
            <h3>История заказов</h3>
            <br/>
            {userProfile.orders.length > 0 && <OrdersView orders={userProfile.orders}/>}
            {userProfile.orders.length === 0 && <p>Пока нет заказов</p>}
            <br/>
            <br/>
            <OutlinedButton label="Выйти" onClick={onLogout}/>
        </div>}
    </>
}

function OrdersView(props: {
    orders: Order[]
}) {
    return <>
        {props.orders.map((order, index) => <Collapsible
            key={index}
            trigger={<><ArrowDropDown/><OrderHeader order={order}/></>}
            triggerWhenOpen={<><ArrowDropUp/><OrderHeader order={order}/></>}
            transitionTime={200}
        >
            <OrderDetails order={order}/>
        </Collapsible>)}
    </>
}

function OrderHeader(props: {
    order: Order
}) {
    const order = props.order
    const totalPrice = order.items.reduce((price, item) => price + item.price * item.quantity, 0)
    return <div className="OrderHeader">
        Заказ от {order.creationDate.toLocaleString("ru")} на сумму {totalPrice.toFixed(2)} {order.currency}
    </div>
}

function OrderDetails(props: {
    order: Order
}) {
    const order = props.order
    return <div>
        <>Статус: {order.status}</>
        <br/>
        <>Способ доставки: {order.shippingMethod}</>
        <br/>
        <>Способ оплаты: {order.paymentMethod}</>
        <br/>
        <br/>
        <>Товары:</>
        <br/>
        {order.items.map((item, index) => {
            const itemLink = item.catalogItem && catalogItemLink(item.catalogItem)
            const qualifiers = getVariantQualifiers(item).join(" ")
            return <div key={index}>
                {itemLink && <a href={itemLink}>{item.title}</a>}
                {!itemLink && item.title}
                {item.catalogItem &&
                    <> {qualifiers} - {item.quantity} шт.
                        - {(item.price * item.quantity).toFixed(2)} {order.currency}</>}
            </div>
        })}
    </div>
}
