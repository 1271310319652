import {Link} from "react-router-dom";
import {getNewsEntry, NewsEntry} from "../../../data/News";
import {PageContentView} from "../PageContentView";
import {DataEntryView} from "../DataEntryView";

export function NewsEntryView() {
    return <DataEntryView
        getData={getNewsEntry}
        dataView={NewsEntryContent}
    />
}

function NewsEntryContent(props: NewsEntry) {
    return <div className="l-container-column is-nopadded">
        <article className="article">
            <header className="l-container-column pageHeading-article">
                <Link to="/news" className="back">&laquo; Все новости</Link>
                <h1>{props.title}</h1>
                <time>{props.publishDate.toLocaleDateString()}</time>
            </header>
            {props.content.map((content, index) => <PageContentView key={index} content={content}/>)}
            <div className="text">
                <div className="tags">
                    <strong>Категория:</strong>
                    <Link to={`/news?category=${props.category}`}>{props.category}</Link>
                </div>
            </div>
        </article>
    </div>
}
