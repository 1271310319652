import {postJson} from "./FetchJson";
import {required} from "../functional/Functions";

export type UserToken = string

export function registerUser(form: {
    name: string,
    email: string,
    password: string,
    phone: string,
}): Promise<UserToken> {
    return postJson('/api/auth/local/register', {
        name: form.name,
        email: form.email,
        username: form.email,
        password: form.password,
        phone: form.phone,
    }).then(parseUserToken)
}

export function loginUser(credentials: {
    email: string,
    password: string,
}): Promise<UserToken> {
    return postJson('/api/auth/local', {
        identifier: credentials.email,
        password: credentials.password,
    }).then(parseUserToken)
}

function parseUserToken(json: any): UserToken {
    return required(json.jwt)
}
