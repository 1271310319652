import {CatalogItem, parseCatalogItem} from "./CatalogItems";
import {Color, parseColor} from "./Color";
import {CatalogCategory} from "./CatalogCategory";

export interface Order {
    creationDate: Date
    status: string
    paymentMethod: string
    shippingMethod: string
    currency: string
    items: OrderItem[]
}

export interface OrderItem {
    sku: string
    title: string
    quantity: number
    price: number
    catalogItem: CatalogItem | null
    qualifier: string | null
    color: Color | null
}

export function parseOrder(json: any): Order {
    return {
        creationDate: new Date(json.createdAt),
        status: json.status,
        paymentMethod: json.paymentMethod,
        shippingMethod: json.shippingMethod,
        currency: json.currency,
        items: json.items.map(parseOrderItem),
    }
}

function parseOrderItem(json: any): OrderItem {
    let catalogItem: CatalogItem | null
    if (json.motorcycle) {
        catalogItem = parseCatalogItem(json.motorcycle, CatalogCategory.MOTORCYCLES)
    } else if (json.part) {
        catalogItem = parseCatalogItem(json.part, CatalogCategory.PARTS)
    } else if (json.accessory) {
        catalogItem = parseCatalogItem(json.accessory, CatalogCategory.ACCESSORIES)
    } else {
        catalogItem = null
    }
    return {
        sku: json.sku,
        title: json.title,
        quantity: json.quantity,
        price: json.price,
        catalogItem: catalogItem,
        qualifier: json.qualifier,
        color: json.color && parseColor(json.color),
    }
}
