import "./styles/Common.css"
import "./styles/Legacy.css"
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './components/App';
import {BrowserRouter} from "react-router-dom";
import {Provider} from "react-redux";
import appStore from "./redux/AppStore";
import {saveAppStateToLocalStorage} from "./redux/LocalStorage";

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <Provider store={appStore}>
                <App/>
            </Provider>
        </BrowserRouter>
    </React.StrictMode>
);

appStore.subscribe(() => {
    saveAppStateToLocalStorage(appStore.getState)
})
