import "./NavIcons.css"
import {Link} from "react-router-dom";
import React from "react";
import {AccountCircleOutlined, ShoppingCartOutlined, SvgIconComponent} from "@mui/icons-material";
import Badge from '@mui/material/Badge';
import {useAppSelector} from "../../redux/Hooks";
import {doNothing} from "../../functional/Functions";
import {useUserToken} from "../../hooks/User";

export function NavIcons() {
    const cartSize = useAppSelector(state => state.cart.entries.length)
    const isLoggedIn = useUserToken() != null
    return <div className="NavIcons">
        <NavIcon link={`/cart`} icon={ShoppingCartOutlined} counter={cartSize} onClick={doNothing}/>
        {!isLoggedIn && <NavIcon link={`/login`} icon={AccountCircleOutlined} counter={0} onClick={doNothing}/>}
        {isLoggedIn && <NavIcon link={`/profile`} icon={AccountCircleOutlined} counter={0} onClick={doNothing}/>}
    </div>;
}

function NavIcon(props: {
    link: string | null,
    icon: SvgIconComponent,
    counter: number | undefined,
    onClick: () => void,
}) {
    const link = props.link
    const IconView = () => <Badge badgeContent={props.counter} color="error">
        {React.createElement(props.icon, {fontSize: "medium", onClick: props.onClick})}
    </Badge>
    return <div className="NavIcon">
        {link && <Link to={link}>
            <IconView/>
        </Link>}
        {!link && <IconView/>}
    </div>
}
