import {CatalogCategory} from "../data/CatalogCategory";
import {accessoryTypesFilter, arrayAndFilter, Filter, motoTypeFilter, partTypesFilter} from "../data/Filters";
import {useCatalogQuery} from "./Catalog";
import {mapOrNull, notNull} from "../functional/Functions";
import {wrapToArray} from "../functional/Arrays";

export function useCatalogFilter(): Filter {
    const [{
        category,
        accessoryType,
        partType,
        motoType,
    }] = useCatalogQuery()
    let filters: Filter[]
    switch (category) {
        case CatalogCategory.MOTORCYCLES:
            filters = [
                mapOrNull(motoType, motoTypeFilter),
            ].filter(notNull)
            break
        case CatalogCategory.PARTS:
            filters = [
                partTypesFilter(wrapToArray(partType)),
            ].filter(notNull)
            break
        case CatalogCategory.ACCESSORIES:
            filters = [
                accessoryTypesFilter(wrapToArray(accessoryType)),
            ].filter(notNull)
            break
    }
    return arrayAndFilter(filters)
}
