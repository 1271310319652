import "./RegisterView.css"
import {OutlinedButton} from "../primitives/OutlinedButton";
import {registerUser} from "../../data/User";
import {setToken} from "../../redux/UserSlice";
import {useDispatch} from "react-redux";
import {PageHeadingPlaceholder} from "../PageHeadingPlaceholder";
import {useEmailInputField, useMobileInputField, useNonEmptyInputField} from "../inputs/InputFields";
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import Loader from "../primitives/Loader";
import Alert from "@mui/material/Alert";
import {PasswordInput} from "../inputs/Inputs";

export function RegisterView() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [isRegistering, setIsRegistering] = useState(false)
    const [errorMessage, setErrorMessage] = useState<string | null>(null)
    const [renderNameInput, validateName] = useNonEmptyInputField("ФИО")
    const [renderEmailInput, validateEmail] = useEmailInputField()
    const [renderPhoneInput, validatePhone] = useMobileInputField()
    const [renderPasswordInput, validatePassword] = useNonEmptyInputField("Пароль", "", PasswordInput)
    const onRegisterClick = () => {
        setErrorMessage(null)
        let [name, email, phone, password] = [
            validateName(),
            validateEmail(),
            validatePhone(),
            validatePassword(),
        ]
        if (name !== null && email !== null && phone !== null && password !== null) {
            setIsRegistering(true)
            registerUser({
                name: name,
                email: email,
                password: password,
                phone: phone,
            }).then(token => {
                dispatch(setToken(token))
                setIsRegistering(false)
                navigate('/')
            }).catch(() => {
                setIsRegistering(false)
                setErrorMessage("Неверный email или пользователь уже существует")
            })
        }
    }
    return <>
        <PageHeadingPlaceholder/>
        <div className="Register Flex Center">
            <div className="RegisterForm">
                <br/>
                <h1>Регистрация нового пользователя</h1>
                <br/>
                <br/>
                <div className="l-feedback feedback">
                    <div className="feedback_inputs">
                        {renderNameInput()}
                        {renderEmailInput()}
                        {renderPhoneInput()}
                        {renderPasswordInput()}
                    </div>
                </div>
                <br/>
                <br/>
                {isRegistering && <Loader/>}
                {!isRegistering && <OutlinedButton label="Зарегистрироваться" onClick={onRegisterClick}/>}
                <br/>
                <br/>
                {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
            </div>
        </div>
    </>
}
