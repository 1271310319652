import {ButtonContent} from "../../data/PageContent";

export function ButtonView(props: {
    content: ButtonContent,
}) {
    return <div>
        <a href={props.content.url} className="button button-clean-orange">{props.content.title}</a>
        <br/>
        <br/>
    </div>
}
