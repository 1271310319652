import {Coordinates} from "../../../data/Coordinates";
import {GoogleMap, Marker, useJsApiLoader} from "@react-google-maps/api";
import {mapContainerStyle} from "./MapContainerStyle";
import {mapOptions} from "./MapOptions";
import Loader from "../../primitives/Loader";

export function Map(props: {
    coordinates: Coordinates
    markerIconUrl: string
}) {
    const markerPosition = {
        lat: props.coordinates.latitude,
        lng: props.coordinates.longitude,
    }
    const {isLoaded} = useJsApiLoader({
        googleMapsApiKey: "AIzaSyAbSVGveGorWf5Pzq_B-YKz2Yd1rKfwNgI"
    })
    const renderMap = () => <div className="contact_media feedback_map event-map">
        <GoogleMap
            mapContainerStyle={mapContainerStyle}
            center={markerPosition}
            options={mapOptions}
            zoom={14}>
            <Marker
                position={markerPosition}
                icon={props.markerIconUrl}
            />
        </GoogleMap>
    </div>
    return isLoaded ? renderMap() : <Loader/>
}
