import {Contacts} from "./Contacts";
import {postJson} from "./FetchJson";

export interface Feedback {
    contacts: Contacts
    message: string
}

export function sendFeedback(feedback: Feedback): Promise<any> {
    return postJson("/api/feedbacks", {
        data: {
            contacts: {
                name: feedback.contacts.name,
                email: feedback.contacts.email,
                phone: feedback.contacts.phone,
            },
            message: feedback.message,
        }
    }).then(response => {
        if (!response.data) throw Error
    });
}
