import React from "react";
import {InputProps} from "./InputField";
import PhoneInput from 'react-phone-number-input/input'

export const TextInput = (props: InputProps) => {
    return <input
        type="text"
        autoComplete="off"
        value={props.value}
        placeholder={props.placeholderText}
        onChange={event => props.onValueChanged(event.target.value)}
    />
}

export const TextAreaInput = (props: InputProps) => {
    return <textarea
        value={props.value}
        autoComplete="off"
        placeholder={props.placeholderText}
        onChange={event => props.onValueChanged(event.target.value)}
    />
}

export const PasswordInput = (props: InputProps) => {
    return <input
        type="password"
        value={props.value}
        autoComplete="new-password"
        placeholder={props.placeholderText}
        onChange={event => props.onValueChanged(event.target.value)}
    />
}

export const MobilePhoneInput = (props: InputProps) => {
    return <PhoneInput
        value={props.value}
        defaultCountry="BY"
        placeholder={props.placeholderText}
        onChange={(value) => props.onValueChanged(value || ``)}
    />
}
