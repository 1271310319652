import {getAppState} from "../redux/AppStore";

export function fetchJson(urlPath: string): Promise<any> {
    let request = {
        method: 'GET',
        headers: {
            "Accept": "application/json",
            ...authorization(),
        },
    }
    return fetch(process.env.REACT_APP_BACKEND_URL + urlPath, request)
        .then((response) => response.json())
        .catch((error) => {
            console.log(error.message)
            throw error
        });
}

export function postJson(urlPath: string, data: any): Promise<any> {
    let request = {
        method: 'POST',
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            ...authorization(),
        },
        body: JSON.stringify(data)
    }
    return fetch(process.env.REACT_APP_BACKEND_URL + urlPath, request)
        .then((response) => response.json())
        .catch((error) => {
            console.log(error.message)
            throw error
        });
}

function authorization(): any {
    const token = getAppState().user.token
    if (token) {
        return {
            Authorization: `Bearer ${token}`,
        }
    } else {
        return {}
    }
}
