export enum CatalogCategory {
    MOTORCYCLES = "motorcycles",
    PARTS = "parts",
    ACCESSORIES = "accessories",
}

export const AllCatalogCategories = Object.values(CatalogCategory)

export namespace CatalogCategoryFactory {
    export function fromString(value: string): CatalogCategory | undefined {
        return Object.values(CatalogCategory).find((it) => it === value)
    }
}

export function categoryDisplayValue(category: CatalogCategory): string {
    switch (category) {
        case CatalogCategory.MOTORCYCLES:
            return "Мотоциклы"
        case CatalogCategory.PARTS:
            return "Запчасти"
        case CatalogCategory.ACCESSORIES:
            return "Одежда и аксессуары"
    }
}

export function categoryTypeKey(category: CatalogCategory): string {
    switch (category) {
        case CatalogCategory.MOTORCYCLES:
            return "moto_type"
        case CatalogCategory.PARTS:
            return "part_type"
        case CatalogCategory.ACCESSORIES:
            return "accessory_type"
    }
}
