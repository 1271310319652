import {fetchJson} from "./FetchJson";
import {parseImage} from "./Images";

export interface MetaData {
    title: string
    description: string
    keywords: string
    faviconUrl: string
}

export const getGlobalMetaData: Promise<MetaData> = fetchJson('/api/meta?populate=deep,3')
    .then((json) => {
        let data = json.data
        return {
            title: data.title,
            description: data.description,
            keywords: data.keywords,
            faviconUrl: parseImage(data.favicon).originalUrl,
        }
    })
