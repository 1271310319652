import {CartEntry} from "./CartEntry";
import {Image} from "./Images";
import {CatalogItem} from "./CatalogItems";
import {CatalogSettings} from "./CatalogSettings";
import {CatalogItemVariant} from "./CatalogItemVariant";
import {isDefined, notNull} from "../functional/Functions";
import {Color} from "./Color";

export function getCatalogItemImage(item: CatalogItem & CatalogSettings): Image {
    let variant = item.variants[0]
    if (variant) {
        return variant.images[0] || item.variants.find((variant) => variant.images.length > 0)?.images[0] || item.imagePlaceholder
    }
    return item.imagePlaceholder
}

export function getCartEntryImage(item: CatalogItem & CartEntry & CatalogSettings): Image {
    let variant = item.variants.find((variant) => variant.id === item.variantId)
    if (variant) {
        return variant.images[0] || item.variants.find((variant) => variant.images.length > 0)?.images[0] || item.imagePlaceholder
    }
    return item.imagePlaceholder
}

export function getCartEntryVariant(item: CatalogItem & CartEntry): CatalogItemVariant {
    return item.variants.find((variant) => variant.id === item.variantId) || item.variants[0]
}

export function getVariantQualifiers(variant: {
    color: Color | null,
    qualifier: string | null,
}): String[] {
    return [
        variant.color?.name,
        variant.qualifier,
    ].filter(notNull).filter(isDefined)
}
