import {fetchJson} from "./FetchJson";
import {CoverContent, ImagesGridContent, ImageWithTextContent, PageContent} from "./PageContent";
import {parsePageContent} from "./PageContentParsing";
import {Image} from "./Images";
import {None, Option, Some} from "@thames/monads";

export interface NewsEntry {
    id: string
    title: string
    category: string
    publishDate: Date
    content: PageContent[]
}

export function getNews(): Promise<NewsEntry[]> {
    return fetchJson('/api/news?populate=deep&sort[0]=priority:desc&sort[1]=publishedAt:desc')
        .then((json) => (json.data as any[]).map(parseNewsEntry))
}

export function getNewsEntry(id: number): Promise<Option<NewsEntry>> {
    return fetchJson(`/api/news/${id}?populate=deep`)
        .then((json) => Some(parseNewsEntry(json.data)))
        .catch(() => None)
}

export function getNewsEntryThumbnailImage(entry: NewsEntry): Image | null {
    return entry.content.find<CoverContent>(isCover)?.image
        || entry.content.find<ImageWithTextContent>(isImageWithText)?.image
        || entry.content.find<ImagesGridContent>(isImagesGrid)?.images[0]
        || null
}

function isCover(content: PageContent): content is CoverContent {
    return content instanceof CoverContent
}

function isImageWithText(content: PageContent): content is ImageWithTextContent {
    return content instanceof ImageWithTextContent
}

function isImagesGrid(content: PageContent): content is ImagesGridContent {
    return content instanceof ImagesGridContent
}

export function parseNewsEntry(json: any): NewsEntry {
    return {
        id: json.id,
        title: json.title,
        category: json.category,
        publishDate: new Date(json.publishedAt),
        content: (json.content as any[]).map(parsePageContent),
    }
}
