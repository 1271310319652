import {Link} from "react-router-dom";
import {PageHeadingPlaceholder} from "./PageHeadingPlaceholder";

export function Page404() {
    return <>
        <PageHeadingPlaceholder/>
        <Content404/>
    </>
}

export function Content404() {
    return <>
        <div className="status status-404">
            <div className="status_text">
                <h1>Страница не найдена</h1>
                <p>404</p>
            </div>
            <Link to="/" className="button button-alpha">Перейти на главную</Link>
        </div>
    </>
}
