import "./CartItems.css"
import "./CartGroup.css"
import {CatalogItem} from "../../../data/CatalogItems";
import {OutlinedButton} from "../../primitives/OutlinedButton";
import {CartItemView} from "./CartItemView";
import React from "react";
import {formattedTotalPrice} from "../../../data/Price";
import {CatalogSettings} from "../../../data/CatalogSettings";
import {CartEntry} from "../../../data/CartEntry";
import {usePreferredCurrency} from "../../../hooks/Currency";

export function CartItems(props: {
    items: (CartEntry & CatalogItem)[],
    catalogSettings: CatalogSettings,
    onSubmit: () => void,
}) {
    const [preferredCurrency] = usePreferredCurrency()
    const selectedCurrency = preferredCurrency || props.catalogSettings.primaryCurrency
    return <div className="CartItems CartGroup article">
        {props.items.map((item, index) =>
            React.createElement(CartItemView, {...item, ...props.catalogSettings, key: index,})
        )}
        <div className="CartItemsTotals">
            <div className="CartItemsTotalsItem CartGroup">
                <label>Всего к оплате</label>
                <div className="CartItemsTotalsValue">{formattedTotalPrice(props.items, selectedCurrency)}</div>
            </div>
        </div>
        <div className="CartItemsCheckout">
            <OutlinedButton label="Оформить заказ" onClick={props.onSubmit}/>
        </div>
    </div>
}
