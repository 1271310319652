import {PageHeading} from "../../data/PageHeading";

function PageHeadingView(props: {
    pageHeading: PageHeading
}) {
    return <header className="l-pageHeading pageHeading pageHeading-lower">
        <div className="pageHeading_bg">
            <div className="bg_cover">
                <img src={props.pageHeading.backgroundImage.originalUrl} alt=""/>
            </div>
        </div>
        <div className="pageHeading_text">
            <h1>{props.pageHeading.title}</h1>
            <h2>{props.pageHeading.subtitle}</h2>
        </div>
    </header>;
}

export default PageHeadingView;
