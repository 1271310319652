export interface ProductInfo {
    storyTitle: string;
    storyText: string;
}

export function parseProductInfo(json: any): ProductInfo {
    return {
        storyTitle: json.storyTitle || "",
        storyText: json.storyText || "",
    }
}
