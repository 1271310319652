import "./CartItemView.css"
import "./CartGroup.css"
import {CatalogItem, catalogItemLink} from "../../../data/CatalogItems";
import {useDispatch} from "react-redux";
import {removeItem, setItem} from "../../../redux/CartSlice";
import {DeleteOutlined} from "@mui/icons-material";
import {formattedLinePrice, formattedPriceWithoutCurrency} from "../../../data/Price";
import {CatalogSettings} from "../../../data/CatalogSettings";
import {CartEntry} from "../../../data/CartEntry";
import {Link} from "react-router-dom";
import {getCartEntryImage, getCartEntryVariant, getVariantQualifiers} from "../../../data/Catalog";
import {usePreferredCurrency} from "../../../hooks/Currency";

export function CartItemView(item: CartEntry & CatalogItem & CatalogSettings) {
    const dispatch = useDispatch()
    const removeFromCart = () => dispatch(removeItem(item))
    const setCartItemCount = (count: number) => {
        dispatch(setItem({...item, count: count}))
    }
    const itemQualifiers = getVariantQualifiers(getCartEntryVariant(item)).join(" ")
    const [preferredCurrency] = usePreferredCurrency()
    const selectedCurrency = preferredCurrency || item.primaryCurrency
    return <div className="CartItemView CartGroup">
        <div className="CartItemViewImage">
            <Link to={catalogItemLink(item)}>
                <img src={getCartEntryImage(item).thumbnailUrl} alt=""/>
            </Link>
        </div>
        <div className="CartItemViewTitle">{`${item.title} ${itemQualifiers}`}</div>
        <div className="CartItemViewPrice">{formattedPriceWithoutCurrency(item, selectedCurrency)}</div>
        <div className="CartItemViewQuantity">
            <small>x</small>
            <input type="number" value={item.count} min="1" onChange={(event) => {
                setCartItemCount(event.target.valueAsNumber)
            }}/>
        </div>
        <div className="CartItemViewRemoval">
            <DeleteOutlined sx={{"&:hover": {opacity: 0.5}}} onClick={removeFromCart}/>
        </div>
        <div className="CartItemViewLinePrice">{formattedLinePrice(item, selectedCurrency)}</div>
    </div>
}
