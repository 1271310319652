import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {AppState} from "./AppStore";
import {CartEntry} from "../data/CartEntry";
import {CartItem} from "../data/CartItem";
import {useAppDispatch, useAppSelector} from "./Hooks";

interface CartState {
    entries: CartEntry[]
}

export const cartSlice = createSlice({
    name: 'cart',
    initialState: {
        entries: [],
    } as CartState,
    reducers: {
        addItem: (state: CartState, action: PayloadAction<CartItem>) => {
            let payload = action.payload
            let cartEntry = findCartEntry(state, payload)
            if (cartEntry) {
                cartEntry.count += 1
            } else {
                state.entries.push({
                    id: payload.id,
                    category: payload.category,
                    variantId: payload.variantId,
                    count: 1,
                })
            }
        },
        setItem: (state: CartState, action: PayloadAction<CartEntry>) => {
            if (action.payload.count > 0) {
                let cartEntry = findCartEntry(state, action.payload)
                if (cartEntry) {
                    cartEntry.count = action.payload.count
                } else {
                    state.entries.push(action.payload)
                }
            } else {
                removeItemFromState(state, action.payload)
            }
        },
        setItems: (state: CartState, action: PayloadAction<CartEntry[]>) => {
            state.entries = action.payload.map((payload) => {
                return {
                    id: payload.id,
                    category: payload.category,
                    variantId: payload.variantId,
                    count: payload.count,
                }
            })
        },
        removeItem: (state: CartState, action: PayloadAction<CartItem>) => {
            removeItemFromState(state, action.payload)
        },
        clear: (state: CartState) => {
            state.entries = []
        },
    },
})

export function useCartItemControl(item: CartItem): [addToCart: () => void, removeFromCart: () => void, isItemInCart: boolean] {
    const dispatch = useAppDispatch()
    const addToCart = () => dispatch(addItem(item))
    const removeFromCart = () => dispatch(removeItem(item))
    const isItemInCart = useAppSelector(isItemInCartSelector(item))
    return [addToCart, removeFromCart, isItemInCart]
}

export const isItemInCartSelector = (item: CartItem) => (state: AppState) => {
    return state.cart.entries.some(sameAsCartItem(item))
}

export const sameAsCartItem = (first: { id: number }) => (second: { id: number }) => first.id === second.id

const findCartEntry = (state: CartState, item: CartItem) => state.entries.find(sameAsCartItem(item))

const removeItemFromState = (state: CartState, item: CartItem) => {
    let index = state.entries.findIndex(sameAsCartItem(item))
    state.entries.splice(index, 1)
}

export const {
    addItem,
    setItem,
    setItems,
    removeItem,
    clear,
} = cartSlice.actions
