import {Image, parseImage} from "./Images";

export interface PageHeading {
    title: string
    subtitle: string
    backgroundImage: Image
}

export function parsePageHeading(json: any): PageHeading {
    return {
        title: json.title,
        subtitle: json.subtitle,
        backgroundImage: parseImage(json.backgroundImage),
    }
}
