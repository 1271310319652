import {parseProductInfo, ProductInfo} from "./ProductInfo";
import {fetchJson} from "./FetchJson";
import {mapOrNull, notNull} from "../functional/Functions";
import {CatalogCategory} from "./CatalogCategory";
import Immutable from "immutable";
import {CatalogItem, parseCatalogItem} from "./CatalogItems";
import {None, Option, Some} from "@thames/monads";

export interface Product extends CatalogItem {
    productInfo: ProductInfo | null
    productSpecs: ProductSpecs
}

export type ProductSpecs = Immutable.OrderedMap<string, ProductSpecsGroup>
export type ProductSpecsGroup = Immutable.OrderedMap<string, string>

export function getProduct(id: number, category: CatalogCategory): Promise<Option<Product>> {
    return fetchJson(`/api/${category}/${id}?populate=deep`)
        .then((json) => Some(parseProduct(json.data, category)))
        .catch(() => None)
}

export function parseProduct(json: any, category: CatalogCategory): Product {
    return {
        ...parseCatalogItem(json, category),
        productInfo: mapOrNull(json.productInfo, parseProductInfo),
        productSpecs: parseSpecs(category)(json),
    }
}

function parseSpecs(category: CatalogCategory): (jsonAttributes: any) => ProductSpecs {
    switch (category) {
        case CatalogCategory.MOTORCYCLES:
            return parseMotorcycleSpecs
        case CatalogCategory.PARTS:
        case CatalogCategory.ACCESSORIES:
            return () => Immutable.OrderedMap()
    }
}

function parseMotorcycleSpecs(json: any): ProductSpecs {
    return Immutable.OrderedMap({
        "Двигатель": mapOrNull(json.engine, (engine) => Immutable.OrderedMap({
            "Конструкция": mapSpec(engine.design),
            "Объём": mapSpec(engine.displacement, (displacement) => `${displacement.toFixed(1)} см³`),
            "Диаметр цилиндра": mapSpec(json.engine?.bore),
            "Ход поршня": mapSpec(engine.stroke),
            "Мощность": mapSpec(engine.power, (power) => `${power} кВт (${(power * 1.35962).toFixed(0)} л.с.)`),
            "Запуск двигателя": mapSpec(engine.starter),
            "Смазка двигателя": mapSpec(engine.lubrication),
            "Коробка передач": mapSpec(engine.transmission),
            "Передаточное число": mapSpec(engine.primaryDrive),
            "Передаточное число цепной передачи": mapSpec(engine.secondaryGearRatio),
            "Охлаждение": mapSpec(engine.cooling),
            "Сцепление": mapSpec(engine.clutch),
            "EMS": mapSpec(engine.ems),
            "Выброс СО2": mapSpec(engine.co2Emissions),
            "Расход топлива": mapSpec(engine.fuelConsumption),
            "Система зажигания": mapSpec(engine.ignition),
        }).filter(notNull)),
        "Шасси": mapOrNull(json.chassis, (chassis) => Immutable.OrderedMap({
            "Рама": mapSpec(chassis.frame),
            "Вилка": mapSpec(chassis.frontSuspension),
            "Задняя подвеска": mapSpec(chassis.rearSuspension),
            "Ход вилки": mapSpec(chassis.suspensionTravelFront),
            "Ход задней подвески": mapSpec(chassis.suspensionTravelRear),
            "Тормозная система спереди": mapSpec(chassis.frontBrake),
            "Тормозная система сзади": mapSpec(chassis.rearBrake),
            "Тормозные диски - диаметр спереди": mapSpec(chassis.frontBrakeDiscDiameter),
            "Тормозные диски - диаметр сзади": mapSpec(chassis.rearBrakeDiscDiameter),
            "ABS": mapSpec(chassis.abs),
            "Цепь": mapSpec(chassis.chain),
            "Угол рулевой колонки": mapSpec(chassis.steeringHeadAngle),
            "Колесная База": mapSpec(chassis.wheelbase),
            "Дорожный просвет": mapSpec(chassis.groundClearance),
            "Высота сиденья": mapSpec(chassis.seatHeight),
            "Емкость топливного бака": mapSpec(chassis.tankCapacity),
            "Вес без топлива": mapSpec(chassis.dryWeight),
        }).filter(notNull)),
    }).filter(notNull).filter(group => group.size > 0)
}

function mapSpec<T>(value: T | undefined | null, mapper: (t: T) => string = (t) => `${t}`): string | null {
    if (value) {
        return mapper(value)
    } else {
        return null
    }
}
