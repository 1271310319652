import {CatalogCategory, CatalogCategoryFactory, categoryTypeKey} from "../data/CatalogCategory";
import {MotoType} from "../data/MotoTypes";
import {isDefined, mapOrNull} from "../functional/Functions";
import {AccessoryType, getAccessoryTypes} from "../data/AccessoryTypes";
import {useData} from "../functional/Hooks";
import {getPartTypes, PartType} from "../data/PartTypes";
import {useSearchParams} from "react-router-dom";

export type CatalogQuery = {
    category: CatalogCategory
    searchString: string
    pageNumber: number
    motoType: MotoType | null
    partType: PartType | null
    accessoryType: AccessoryType | null
}

export type CatalogQueryUpdate = {
    category?: CatalogCategory
    searchString?: string
    pageNumber?: number
    motoType?: MotoType | null
    partType?: PartType | null
    accessoryType?: AccessoryType | null
}

export function useCatalogQuery(): [CatalogQuery, (queryUpdate: CatalogQueryUpdate) => void] {
    const [searchParams, setSearchParams] = useSearchParams()
    const allPartTypes = useData(() => getPartTypes) || []
    const allAccessoryTypes = useData(() => getAccessoryTypes) || []
    return [{
        category: mapOrNull(searchParams.get("category"), CatalogCategoryFactory.fromString) || CatalogCategory.MOTORCYCLES,
        searchString: searchParams.get("search") || "",
        pageNumber: mapOrNull(searchParams.get("page"), parseInt) || 1,
        motoType: searchParams.get(categoryTypeKey(CatalogCategory.MOTORCYCLES)),
        partType: mapOrNull(searchParams.get(categoryTypeKey(CatalogCategory.PARTS)), (s: string) => allPartTypes.find((type) => type.title === s) || null),
        accessoryType: mapOrNull(searchParams.get(categoryTypeKey(CatalogCategory.ACCESSORIES)), (s: string) => allAccessoryTypes.find((type) => type.title === s) || null),
    }, (queryUpdate) => {
        setSearchParams((searchParams) => {
            if (queryUpdate.category) {
                searchParams.delete("category")
                searchParams.append("category", queryUpdate.category)
            }
            if (queryUpdate.pageNumber) {
                searchParams.delete("page")
                searchParams.append("page", queryUpdate.pageNumber.toString())
            }
            if (isDefined(queryUpdate.searchString)) {
                searchParams.delete("search")
                if (queryUpdate.searchString) {
                    searchParams.append("search", queryUpdate.searchString)
                }
            }
            if (isDefined(queryUpdate.motoType)) {
                searchParams.delete(categoryTypeKey(CatalogCategory.MOTORCYCLES))
                if (queryUpdate.motoType) {
                    searchParams.append(categoryTypeKey(CatalogCategory.MOTORCYCLES), queryUpdate.motoType)
                }
            }
            if (isDefined(queryUpdate.partType)) {
                searchParams.delete(categoryTypeKey(CatalogCategory.PARTS))
                if (queryUpdate.partType) {
                    searchParams.append(categoryTypeKey(CatalogCategory.PARTS), queryUpdate.partType.title)
                }
            }
            if (isDefined(queryUpdate.accessoryType)) {
                searchParams.delete(categoryTypeKey(CatalogCategory.ACCESSORIES))
                if (queryUpdate.accessoryType) {
                    searchParams.append(categoryTypeKey(CatalogCategory.ACCESSORIES), queryUpdate.accessoryType.title)
                }
            }
            return searchParams
        })
    }]
}
